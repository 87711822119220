<template>
  <div class="analytics-result-charts">
    <LoadingSpinner
      v-if="programsLoading"
      :custom-text="$t('recordsBeingGenerated')"
      class="text-center mt-5"
    />
    <AnalyticsChipWrapper
      v-else
      :service-line-items="items"
      name="AnalyticsChipWrapper"
      @updateChartFilter="updateChartCollection"
    />
    <p
      v-if="!programs.length && !programsLoading"
      class="no-charts-data"
    >
      {{ $t('analyticsChartNoRecords') }}
    </p>
    <div
      v-else
      class="analytics-result-charts-program"
    >
      <AnalyticsChartVirtualScroll
        ref="AnalyticsChartVirtualScroll"
        :key="AnalyticsChartVirtualScrollKey"
        :no-records-message="noDataFoundFor"
        :is-visible="isVisible"
        :chart-data="chartCollection"
      />
    </div>
  </div>
</template>

<script>
import AnalyticsChipWrapper from '@/components/analytics/outcomesExplorer/charts/AnalyticsChipWrapper'
import AnalyticsChartVirtualScroll from '@/components/analytics/outcomesExplorer/charts/AnalyticsChartVirtualScroll'
import { ANALYTICS_GET_PROGRAMS_FILTERED } from '@/store/modules/analytics/outcomes-explorer/constants.js' // ANALYTICS_SET_SELECTED_PROGRAM_CHART
import LoadingSpinner from '@/components/common/LoadingSpinner'
import { mapGetters } from 'vuex'
import { Helpers } from '@/mixins/Helpers'

export default {
  name: 'AnalyticsResultCharts',
  components: {
    AnalyticsChartVirtualScroll,
    AnalyticsChipWrapper,
    LoadingSpinner
  },
  mixins: [Helpers],
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      AnalyticsChartVirtualScrollKey: 0,
      chartCollection: [],
      noDataFoundMsg: '',
      noDataFoundFor: this.$t('noDataFoundFor', { name: 'your selections' })
    }
  },
  computed: {
    ...mapGetters({
      query: 'getAnalyticsApiQuery',
      allPrograms: 'getServiceLinesForChips',
      // WIP - TODO - remove programs here and get a loading state of analyticsProgramsSelected instead
      programs: 'getAnalyticsProgramsFiltered',
      programsLoading: 'getAnalyticsProgramsFilteredLoading',
      chartCollectionLoading: 'getAnalyticsChartCollectionLoading'
    }),
    items () {
      const items = this.allPrograms.map(p => ({ label: p.name, value: p.uuid, state: 'unselected' }))
      const sorted = items.sort((a, b) => a.label.localeCompare(b.label))
      return sorted
    }
  },
  created () {
    this.refreshView()
  },
  methods: {
    refreshView () {
      // Get data but don't use global/page level loader
      this.$genericDispatch(ANALYTICS_GET_PROGRAMS_FILTERED, this.query.criteria, true)
    },
    updateChartCollection (obj) {
      this.chartCollection = obj.chartData
      this.AnalyticsChartVirtualScrollKey += 1
      this.noDataFoundFor = obj.selectedChartChipCount === 0 ? '' : this.$t('noDataFoundFor', { name: 'your selections' })
    }
  }
}
</script>

<template>
  <MeasureQuestionResponse
    v-if="props.column.isMeasureQuestionItem"
    :question="props.formattedRow.questions[props.column.itemIndex]"
  />
  <span
    v-else-if="props.column.label === $t('analyticsRecordsData.completedBy')"
    class="text-capitalize"
  >
    {{ props.formattedRow[props.column.field] }} <span v-if="!isPatient">({{ props.row.respondent_relationship }})</span>
  </span>
  <span
    v-else-if="nameFields.includes(props.column.label)"
    class="text-capitalize"
  >
    {{ props.formattedRow[props.column.field] }}
  </span>
  <span
    v-else-if="props.column.label === $t('analyticsRecordsData.gender')"
    class="text-capitalize"
  >
    {{ getGender(props.formattedRow[props.column.field]) }}
  </span>
  <span v-else-if="programFields.includes(props.column.label)">
    <span v-if="!hideProgramFields">{{ props.formattedRow[props.column.field] }}</span>
  </span>
  <span v-else-if="locationFields.includes(props.column.label)">
    <span v-if="!hideLocationFields">{{ props.formattedRow[props.column.field] }}</span>
  </span>
  <CondensedListOfItems
    v-else-if="props.column.label === $t('analyticsRecordsData.clinician')"
    :items="props.formattedRow[props.column.field]"
    :text="this.$t('clinicians')"
  />
  <CondensedListOfItems
    v-else-if="props.column.label === $t('analyticsRecordsData.clinical_team')"
    :items="props.formattedRow[props.column.field]"
    :text="this.$tc('teams', 2)"
  />
  <span v-else-if="props.column.label === $t('analyticsRecordsData.score_type')">
    <template v-if="!props.row.skipped_answers">
      <span
        v-for="(score, index) in props.row.scores"
        :key="index"
        class="text-capitalize"
      >
        {{ score.label }}<br>
      </span>
    </template>
  </span>
  <span v-else-if="props.column.label === $t('analyticsRecordsData.score')">
    <template v-if="!props.row.skipped_answers">
      <span
        v-for="(score, index) in props.row.scores"
        :key="index"
      >
        {{ score.value }} <br>
      </span>
    </template>
  </span>
  <span v-else-if="props.column.label === $t('analyticsRecordsData.completed_on_due_date')">
    {{ getYesOrNo(props.formattedRow[props.column.field]) }}
  </span>
  <span v-else-if="props.column.label === $t('analyticsRecordsData.lot') || props.column.label === $t('analyticsRecordsData.lotAtDischarge')">
    {{ props.formattedRow[props.column.field] }}
  </span>
  <span v-else-if="dateFields.includes(props.column.label)">
    {{ getDate(props.formattedRow[props.column.field], props.column.field) }}
  </span>

  <span v-else-if="props.column.label === $t('analyticsRecordsData.primary_insurance_provider') || props.column.label === $t('analyticsRecordsData.primary_member_id') || props.column.label === $t('analyticsRecordsData.primary_group_id') || props.column.label === $t('analyticsRecordsData.secondary_insurance_provider') || props.column.label === $t('analyticsRecordsData.secondary_member_id') || props.column.label === $t('analyticsRecordsData.secondary_group_id')">
    {{ props.formattedRow[props.column.field] }}
  </span>

  <span v-else>
    {{ props.formattedRow[props.column.field] || '-' }}
  </span>
</template>

<script>
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import { GENDER_LOCAL_MAP } from '@/mixins/constants/GenderValues'
import CondensedListOfItems from '@/components/common/CondensedListOfItems'
import MeasureQuestionResponse from '@/components/common/MeasureQuestionResponse'
import moment from 'moment'

export default {
  name: 'AnalyticsDataTableItem',
  components: { CondensedListOfItems, MeasureQuestionResponse },
  mixins: [DateTimeHelper],
  props: ['props', 'hideProgramFields', 'hideLocationFields'],
  data () {
    return {
      locationFields: [
        this.$t('analyticsRecordsData.location_name')
      ],
      programFields: [
        this.$t('analyticsRecordsData.program_name'),
        this.$t('analyticsRecordsData.program_id'),
        this.$t('analyticsRecordsData.program_status')
      ],
      utcFields: [
        'birthdate'
      ],
      dateFields: [
        this.$t('analyticsRecordsData.completion_date'),
        this.$t('analyticsRecordsData.due_date'),
        this.$t('analyticsRecordsData.dob'),
        this.$t('analyticsRecordsData.admission_date'),
        this.$t('analyticsRecordsData.discharge_date')
      ],
      nameFields: [
        this.$t('analyticsRecordsData.first_name'),
        this.$t('analyticsRecordsData.last_name')
      ]
    }
  },
  computed: {
    isPatient () {
      return this.props.row.respondent_owl_id === this.props.row.patient_owl_id
    }
  },
  methods: {
    getDate (date, field) {
      if (date === 0) { // Handle special case for 0 milliseconds (1970-01-01)
        date = 1
      }
      if ((this.utcFields.includes(field))) {
        return date ? moment.utc(date * 1000).format('YYYY-MM-DD') : '-'
      } else {
        return date ? this.$toLocal(date * 1000) : '-'
      }
    },
    getYesOrNo (value) {
      return value ? this.$t('yes') : this.$t('noUpper')
    },
    getGender (value) {
      return GENDER_LOCAL_MAP.hasOwnProperty(value) ? this.$t(GENDER_LOCAL_MAP[value]) : value // eslint-disable-line no-prototype-builtins
    }
  }
}
</script>

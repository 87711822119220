<template>
  <div class="dropdown custom-dropdown">
    <label
      class="btn-nav-bar-toggle dropdown-label"
      :class="{active: selectedItemInDropdown}"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      @click="toggleOpen"
    >
      {{ dropdownLabel }} <i
        class="caret-icon-right fa"
        :class="[open ? 'fa-caret-up' : 'fa-caret-down']"
      />
    </label>
    <ul
      class="dropdown-menu"
      :class="{show: open}"
      aria-labelledby="dropdownMenuButton"
    >
      <li
        v-for="(item, i) in items"
        :key="i"
      >
        <button
          type="button"
          class="dropdown-item"
          :class="{active: objectsAreTheSameHack(item, value)}"
          @click="selectOption(item)"
        >
          <span>{{ item.label }}</span>
          <i
            v-if="objectsAreTheSameHack(item, value)"
            class="fa fa-check check-icon-right"
          />
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CustomDropdown',
  props: ['items', 'value'],
  data () {
    return {
      open: false
    }
  },
  computed: {
    selectedItemInDropdown () {
      return this.items.find((i) => {
        return this.objectsAreTheSameHack(i, this.value)
      })
    },
    dropdownLabel () {
      if (this.value && this.selectedItemInDropdown) {
        return this.selectedItemInDropdown.label
      }
      return this.items.length + ' More'
    }
  },
  methods: {
    // Note: The object comparison is converted to string since there is no deep-object-comparison support in the codebase.
    // This is needed to be able to create object for different sources (that will not contain the same object reference)
    // since evaluations like { a: 1 } === { a: 1 } will not work sometimes.
    // This is to avoid having to fetch exact same Object reference when evaluation object that has the same attribute and values.
    // It is recommended to create a Object-Comparison solution since this is not perfect but a hack.
    objectsAreTheSameHack (objectOne, objectTwo) {
      const itemValue = typeof objectOne.value === 'object' ? JSON.stringify(objectOne.value) : objectOne.value
      const selectedValue = typeof objectTwo === 'object' ? JSON.stringify(objectTwo) : objectTwo
      return itemValue === selectedValue
    },
    toggleOpen () {
      this.open = !this.open
    },
    selectOption (selectedOption) {
      this.$emit('input', selectedOption.value)
      this.open = false
    }
  }
}
</script>

<style lang="scss" scoped>
i {
  float: right;
}
.check-icon-right {
  float: right;
  color: darkseagreen;
  font-size: 13px;
  position: relative;
  top: 2px;
}
.dropdown-item.active {
  background-color: #f8f9fa;;
  color: black;
}
.dropdown-item:active {
  color: black;
}
.caret-icon-right {
  float: right;
  margin-left: 5px;
}
</style>

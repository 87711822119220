<template>
  <AnalyticsFilter
    ref="analyticsFilter"
    :filters="computedFilters"
    :custom-button-text="getButtonText"
    :before-execute="beforeExecute"
    :error="error"
    :show-error="showError"
    class="program-filter"
    @executeFilter="executeFilter(false, true)"
    @resetModel="cancelChanges"
  >
    <template
      slot="title"
      slot-scope="{ filter, selectAll, selectNone }"
    >
      <div class="filter-title program-filter-title">
        <div class="program-location-selector">
          <div
            v-if="canFilterByLocationOrProgram()"
            class="radio-container"
          >
            <CustomRadio
              id="location_option"
              v-model="selectedFilter"
              name="selectedFilter"
              :label="$t('programFilter.outpatient')"
              :val="'locationFilter'"
            />
            <CustomRadio
              id="program_option"
              v-model="selectedFilter"
              name="selectedFilter"
              :label="$t('programFilter.programs')"
              :val="'programFilter'"
            />
          </div>
          <h5 v-else-if="canFilterByLocationOnly()">
            {{ $t('programFilter.outpatient') }}
          </h5>
          <h5 v-else-if="canFilterByProgramOnly()">
            {{ $t('programFilter.programs') }}
          </h5>
        </div>

        <div>
          <div class="program-filter-feedback">
            {{ filter.model.length }} selected (select up to {{ filter.limit }})
          </div>
          <div class="program-filter-controls">
            <button
              class="btn btn-bare"
              @click="selectAll(filter)"
            >
              {{ $t('all') }}
            </button> <span>|</span> <button
              class="btn btn-bare"
              @click="selectNone(filter)"
            >
              {{ $t('none') }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </AnalyticsFilter>
</template>

<script>
import AnalyticsFilter from '@/components/analytics/filters/AnalyticsFilter'
import { GET_ANALYTICS_PROGRAMS, GET_ANALYTICS_LOCATIONS } from '@/store/modules/analytics/outcomes-explorer/constants.js'
import { Helpers } from '@/mixins/Helpers'
import { AnalyticsFilterHelpers } from '@/mixins/analytics/AnalyticsFilterHelpers'
import { waitForEventToFinish } from '@/mixins/Common/userHelperFuncions.js'
import { mapGetters } from 'vuex'
import { SS_APPOINTMENTS, SS_PROGRAMS, SS_PROGRAMS_AND_APPOINTMENTS } from '@/data/setttingStates'
import CustomRadio from '@/components/common/CustomRadio'

export default {
  name: 'AnalyticsProgramsLocationsFilter',
  components: { AnalyticsFilter, CustomRadio },
  mixins: [Helpers, AnalyticsFilterHelpers],
  data () {
    return {
      selectedFilter: '',
      selectedPrograms: [],
      selectedLocations: [],
      buttonText: '',
      programFilter: {
        limit: 10,
        required: true,
        title: this.$tc('programs', 2),
        type: 'checkbox',
        filterName: 'program_uuid',
        filterKey: 'programFilter',
        selectedKey: 'selectedPrograms',
        availableKey: 'analyticsProgramFilter',
        label: 'name',
        model: [],
        items: []
      },
      locationFilter: {
        limit: 10,
        required: true,
        title: this.$tc('locations', 2),
        type: 'checkbox',
        filterName: 'location_uuid',
        filterKey: 'locationFilter',
        selectedKey: 'selectedLocations',
        availableKey: 'analyticsLocationFilter',
        label: 'name',
        initAllSelected: this.selectAllLocations,
        model: [],
        items: []
      }
    }
  },
  computed: {
    ...mapGetters({
      analyticsProgramFilter: 'getAnalyticPrograms',
      analyticsLocationFilter: 'getAnalyticsLocations',
      analyticsCurrentQuery: 'getAnalyticsCurrentQuery',
      analyticSelectedPrograms: 'getAnalyticsProgramsSelected'
    }),
    programFilterSelected () {
      return this.selectedFilter === 'programFilter'
    },
    computedFilters () {
      const cfilter = this.programFilterSelected ? [this.programFilter] : [this.locationFilter]
      // Sort Locations alphabetically
      if (!this.programFilterSelected) {
        cfilter[0].items.sort((a, b) => a.label.localeCompare(b.label))
      }
      return cfilter
    },
    getButtonText () {
      return this.buttonText
    },
    selectAllLocations () {
      return this.settings.setting_state === SS_APPOINTMENTS || this.settings.setting_state === SS_PROGRAMS_AND_APPOINTMENTS
    },
    error () {
      if (this.programFilterSelected) {
        return this.errorType === 'max'
          ? this.$t('noMoreThanXPrograms', { limit: this.programFilter.limit })
          : this.$t('atLeast1Program')
      } else {
        return this.errorType === 'max'
          ? this.$t('noMoreThanXLocations', { limit: this.locationFilter.limit })
          : this.$t('atLeast1Location')
      }
    }
  },
  created () {
    this.setDefaultFilter()
    this.$genericDispatch(GET_ANALYTICS_PROGRAMS).then(() => {
      this.setAnalyticsFilterItems(this.programFilter)
    })
    this.$genericDispatch(GET_ANALYTICS_LOCATIONS).then(() => {
      this.setAnalyticsFilterItems(this.locationFilter)
    })
    this.locationFilter.initAllSelected = this.selectAllLocations
  },
  methods: {
    open () {
      waitForEventToFinish(() => !this.analyticProgramsLoading).then(() => {
        this.$refs.analyticsFilter.opened = true
      })
    },
    updateValuesFromQuery (savedQuery) {
      const data = Object.assign({}, savedQuery)
      return new Promise((resolve) => {
        if (data.query && data.query.program_or_location_filter) {
          this.selectedFilter = data.query.program_or_location_filter
        }
        if ((this.programFilterSelected && data.query.program_uuid) || (!this.programFilterSelected && data.query.location_uuid)) {
          this.computedFilters[0].model = this.programFilterSelected ? data.query.program_uuid : data.query.location_uuid
        }
        this.executeFilter(true)
        resolve(true)
      })
    },
    getFilterLabel (pId) {
      const filter = this.programFilterSelected ? this.programFilter.items.find(f => f.value === pId) : this.locationFilter.items.find(f => f.value === pId)
      return filter.label
    },
    resetFilter () {
      this.resetToDefaultSelection()
      this.executeFilter(false)
    },
    executeFilter (noEmit, isManual) {
      if (isManual) {
        this.$emit('manualChange')
      }
      this.executeFilterAction(noEmit)
      this.updateCurrentFilter({ field: 'program_or_location_filter', value: this.selectedFilter })
      this.updateCurrentFilter({ field: 'scorableFilter', value: true })
      this.updateCurrentFilter({ field: 'results_to_include', value: 'all_results' })
      if (this.programFilterSelected) {
        this.$emit('programsSelected', this.selectedPrograms)
        this.clearAnalyticsFilterItems('location_uuid')
      } else {
        this.$emit('programsSelected', this.selectedLocations)
        this.clearAnalyticsFilterItems('program_uuid')
      }
      this.buttonText = this.getCustomButtonText()
    },
    /**
     * Check setting_state to see which filters the user can access
     */
    setDefaultFilter () {
      this.selectedFilter =
        this.canFilterByLocationOnly()
          ? 'locationFilter'
          : this.canFilterByProgramOnly()
            ? 'programFilter'
            : 'locationFilter'
    },
    canFilterByLocationOnly () {
      return this.settings.setting_state === SS_APPOINTMENTS
    },
    canFilterByProgramOnly () {
      return this.settings.setting_state === SS_PROGRAMS
    },
    canFilterByLocationOrProgram () {
      return this.settings.setting_state === SS_PROGRAMS_AND_APPOINTMENTS
    },
    getCustomButtonText () {
      const currentQueryFilter = this.analyticsCurrentQuery.program_or_location_filter
      if (!currentQueryFilter) {
        return ''
      }
      const currentSelectedItem = currentQueryFilter === 'programFilter' ? 'program_uuid' : 'location_uuid'
      const currentSelectedFilter = this.analyticsCurrentQuery[currentSelectedItem]
      const selectedFilterLength = currentSelectedFilter.length
      if (!selectedFilterLength) {
        return ''
      }
      const filterText = currentQueryFilter === 'programFilter' ? 'programs' : 'locations'
      const allFilterText = currentQueryFilter === 'programFilter' ? 'allPrograms' : 'allLocations'
      const analyticsFilterLength = currentQueryFilter === 'programFilter' ? this.analyticsProgramFilter.length : this.analyticsLocationFilter.length
      const selectedAllPrimary = analyticsFilterLength === selectedFilterLength
      const singleSelectionLabel = this.getFilterLabel(currentSelectedFilter[0])
      return selectedFilterLength === 1 ? singleSelectionLabel : selectedAllPrimary ? this.$t(allFilterText) : `${selectedFilterLength} ${this.$tc(filterText, 2)}`
    },
    cancelChanges () {
      this.cancelModelChange(
        this[this.analyticsCurrentQuery.program_or_location_filter],
        this.analyticSelectedPrograms,
        this.analyticsCurrentQuery.program_or_location_filter)
    }
  }
}
</script>

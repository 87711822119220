<template>
  <AnalyticsChartWrapper
    ref="chartWrapper"
    :config="data"
    :horizontal="true"
    @titleChanged="changeHighChartsTitle"
    @captionChanged="changeHighChartsCaption"
  >
    <template slot="chart">
      <LineChart
        ref="chartRef"
        :chart="data"
        :exporting="{width: 1200, spacingRight: 325}"
        @adjustMenuIcon="adjustMenuIcon"
      />
    </template>
  </AnalyticsChartWrapper>
</template>

<script>
import AnalyticsChartWrapper from '@/components/analytics/outcomesExplorer/charts/AnalyticsChartWrapper'
import LineChart from '@/components/common/LineChart'
import { ChartChangesHelper } from '@/mixins/analytics/Charts/ChartChangesHelper'

export default {
  name: 'AnalyticsResultChartsOutcomesScoresOverTime',
  components: {
    AnalyticsChartWrapper,
    LineChart
  },
  mixins: [ChartChangesHelper],
  props: {
    data: {}
  }
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <CustomCheckbox
    :id="`id_${filter.title}`"
    v-model="filter.model"
    :name="`label_${filter.title}`"
    :val="item.value"
    :custom-value="computedValue"
  >
    <span slot="customLabel">
      <input
        v-model="from"
        type="text"
        class="custom-input-age"
      >
      <span class="custom-input-to">to</span>
      <input
        v-model="to"
        type="text"
        class="custom-input-age"
      >
    </span>
  </CustomCheckbox>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex'
import CustomCheckbox from '@/components/common/CustomCheckbox'
import { componentRefreshSingleton } from '@/mixins/ComponentRefresh/ComponentRefreshSingleton'
import { ANALYTICS_UPDATE_CURRENT_QUERY } from '@/store/modules/analytics/outcomes-explorer/constants.js'

export default {
  name: 'CustomRangeAge',
  components: { CustomCheckbox },
  props: ['filter', 'item'],
  data () {
    return {
      from: null,
      to: null
    }
  },
  computed: {
    ...mapGetters({
      currentQuery: 'getAnalyticsCurrentQuery'
    }),
    computedValue () {
      const from = !this.from ? 0 : this.from
      const to = !this.to ? 0 : this.to
      return from || to ? `${from}_${to}` : ''
    }
  },
  watch: {
    currentQuery: {
      handler (val) {
        if (!val.age.includes('custom')) {
          this.from = null
          this.to = null
        }
      },
      deep: true
    },
    'from' () {
      this.updateValueOnQuery()
    },
    'to' () {
      this.updateValueOnQuery()
    }
  },
  created () {
    componentRefreshSingleton.addComponentToRefresh(this.$options.name, this)
  },
  beforeDestroy () {
    componentRefreshSingleton.removeComponentToRefresh(this.$options.name)
  },
  methods: {
    ...mapMutations({
      updateCurrentFilter: ANALYTICS_UPDATE_CURRENT_QUERY
    }),
    refreshView (savedQuery) {
      if (savedQuery && savedQuery.query && savedQuery.query.customAgeRange) {
        this.from = savedQuery.query.customAgeRange.from
        this.to = savedQuery.query.customAgeRange.to
      }
    },
    updateValueOnQuery () {
      const value = { to: this.to, from: this.from }
      this.updateCurrentFilter({ field: 'customAgeRange', value: value })
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-input-to {
  margin-left: 5px;
}
</style>

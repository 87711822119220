<template>
  <AnalyticsFilter
    :filters="computedFilters"
    :custom-button-text="customButtonText"
    class="analytics-date-filter"
    @executeFilter="applyFilterAction(false)"
    @resetModel="cancelChanges"
  >
    <template
      slot="filter-content"
      slot-scope="{ filter, items }"
    >
      <div
        v-for="(item, i) in items"
        :key="i"
        class="filter-content-item"
      >
        <CustomRadio
          :id="`date_${item.value}`"
          v-model="filter.model"
          :label="item.label"
          :class="{'custom-range-date' : item.value === 'custom'}"
          :val="item.value"
          name="label_date"
          @input="setDatepickerValues"
        >
          <!-- todo refactor into a range component -->
          <span
            v-if="item.value === 'custom'"
            slot="customLabel"
          >
            <div class="datepicker-container">
              <span>{{ $t('from') }}</span>
              <div
                ref="datepickerFrom"
                class="datepicker-wrapper"
              >
                <datepicker
                  ref="fromRef"
                  :value="datepickerFrom"
                  :disabled="isDatepickerDisabled(filter.model)"
                  :disabled-dates="fromDisabledDates"
                  class="form-control"
                  format="MMM dd, yyyy"
                  @selected="dateSelected('datepickerFrom', $event)"
                >
                  <button
                    slot="afterDateInput"
                    :disabled="isDatepickerDisabled(filter.model)"
                    class="btn btn-bare"
                    @click.stop="openDatepicker('fromRef')"
                  >
                    <i class="fal fa-calendar" />
                  </button>
                </datepicker>
              </div>
            </div>
            <div
              ref="datepickerTo"
              class="datepicker-container"
            >
              <span>{{ $t('to') }}</span>
              <div class="datepicker-wrapper">
                <datepicker
                  ref="toRef"
                  :value="datepickerTo"
                  :disabled="isDatepickerDisabled(filter.model)"
                  :disabled-dates="toDisabledDates"
                  class="form-control"
                  format="MMM dd, yyyy"
                  @selected="dateSelected('datepickerTo', $event)"
                >
                  <button
                    slot="afterDateInput"
                    :disabled="isDatepickerDisabled(filter.model)"
                    class="btn btn-bare"
                    @click.stop="openDatepicker('toRef')"
                  >
                    <i class="fal fa-calendar" />
                  </button>
                </datepicker>
              </div>
            </div>
          </span>
        </CustomRadio>
      </div>
    </template>
  </AnalyticsFilter>
</template>

<script>
import AnalyticsFilter from '@/components/analytics/filters/AnalyticsFilter'
import { ANALYTICS_UPDATE_CURRENT_QUERY, ANALYTICS_DELETE_PROPERTY_FROM_QUERY, ANALYTICS_SET_CUSTOM_BUTTON_TEXT } from '@/store/modules/analytics/outcomes-explorer/constants.js'
import CustomRadio from '@/components/common/CustomRadio'
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'
import { AnalyticsFilterHelpers } from '@/mixins/analytics/AnalyticsFilterHelpers'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import { mapGetters, mapMutations } from 'vuex'

const DATE = new Date()
const END_OF_PREVIOUS_DAY = new Date(DATE.getFullYear(), DATE.getMonth(), 0, 23, 59, 59)
const START_OF_LAST_MONTH = new Date(DATE.getFullYear(), DATE.getMonth() - 1, 1)
const END_OF_LAST_MONTH = new Date(DATE.getFullYear(), DATE.getMonth(), 0, 23, 59, 59)
const START_OF_YEAR = new Date(DATE.getFullYear(), 0)
const START_OF_PREVIOUS_YEAR = new Date(DATE.getFullYear() - 1, 0)
const END_OF_PREVIOUS_YEAR = new Date(DATE.getFullYear() - 1, 11, 31, 23, 59, 59)

export default {
  name: 'AnalyticsDate',
  components: { AnalyticsFilter, CustomRadio, Datepicker },
  mixins: [AnalyticsFilterHelpers, DateTimeHelper],
  props: {
    currentQueryCompletedType: {
      type: String,
      default: null
    },
    currentQueryCustomRange: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      selectedDate: [],
      datepickerFrom: null,
      datepickerTo: null,
      dateFilter: {
        title: this.$t('date'),
        type: 'radio',
        filterName: 'completedType',
        filterKey: 'dateFilter',
        selectedKey: 'selectedDate',
        label: 'name',
        model: [],
        hardcodedItems: true,
        items: [{
          label: this.$t('dates.lastMonth'),
          boxLabel: moment.unix(this.getStartOfLastMonth()).format('MMM YYYY'),
          customValue: { greater_than: this.getStartOfLastMonth(), less_than: this.getEndOfLastMonth() },
          value: 'lastMonth',
          initSelected: true
        }, {
          label: this.$t('dates.yearToDate'),
          boxLabel: this.$t('dates.yearToDateWithDate', { date: moment.unix(this.getStartOfLastMonth()).format('MMM YYYY') }),
          customValue: { greater_than: this.getStartOfYearOfLastMonth(), less_than: this.getEndOfLastMonth() },
          value: 'yearToDate'
        }, {
          label: this.$t('dates.lastYear'),
          boxLabel: this.$t('dates.lastYearWithDate', { date: moment.unix(this.getStartOfLastYear()).format('YYYY') }),
          customValue: { greater_than: this.getStartOfLastYear(), less_than: this.getEndOfLastYear() },
          value: 'lastYear'
        }, {
          label: this.$t('dates.allTime'),
          value: 'allTime'
        }, {
          label: this.$t('dates.custom'),
          value: 'custom'
        }]
      }
    }
  },
  computed: {
    ...mapGetters({
      analyticsCurrentQuery: 'getAnalyticsCurrentQuery',
      generalSettings: 'generalSettings'
    }),
    clientTime () {
      return this.generalSettings.client_time_zone
    },
    customButtonText () {
      switch (this.currentQueryCompletedType) {
        case 'custom':
          return this.customRangeLabel(this.currentQueryCustomRange)

        case 'lastMonth':
          return moment().tz(this.clientTime).add(-1, 'month').format('MMM YYYY')

        case 'yearToDate':
          return this.$t('dates.yearToDateWithDate', { date: moment().tz(this.clientTime).add(-1, 'month').format('MMM YYYY') })

        case 'lastYear':
          return this.$t('dates.lastYearWithDate', { date: moment().tz(this.clientTime).add(-1, 'year').format('YYYY') })

        case 'allTime':
          return this.$t('dates.allTime')

        default:
          return this.$t(this.currentQueryCompletedType)
      }
    },
    allResultsSelected () {
      return this.analyticsCurrentQuery && this.analyticsCurrentQuery.results_to_include && this.analyticsCurrentQuery.results_to_include === 'all_results'
    },
    computedFilters () {
      return [this.dateFilter]
    },
    fromDisabledDates () {
      return {
        from: this.datepickerTo
      }
    },
    toDisabledDates () {
      return {
        to: this.datepickerFrom
      }
    }
  },
  watch: {
    'analyticsCurrentQuery.dateFilterMode' () {
      this.updateFilterValuesBasedOnResultsToInclude()
    },
    'analyticsCurrentQuery.results_to_include' () {
      this.updateFilterValuesBasedOnResultsToInclude()
    }
  },
  created () {
    this.updateFilterValuesBasedOnResultsToInclude()
    this.setDateFilterItems()
    document.addEventListener('click', this.handleClickOutside)
  },
  updated () {
    this.setCustomButtonText(this.customButtonText)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    ...mapMutations({
      updateCurrentQuery: ANALYTICS_UPDATE_CURRENT_QUERY,
      deletePropertyFromCurrentFilter: ANALYTICS_DELETE_PROPERTY_FROM_QUERY,
      setCustomButtonText: ANALYTICS_SET_CUSTOM_BUTTON_TEXT
    }),
    isDatepickerDisabled (model) {
      return model !== 'custom'
    },
    openDatepicker (ref) {
      this.$refs[ref][0].showCalendar()
    },
    handleClickOutside (event) {
      const fromRef = this.$refs.datepickerFrom[0]
      const toRef = this.$refs.datepickerTo[0]
      const target = event.target

      if (fromRef !== target && !fromRef.contains(target) && target.name !== 'label_date') {
        this.$refs.fromRef[0].close()
      }

      if (toRef !== target && !toRef.contains(target) && target.name !== 'label_date') {
        this.$refs.toRef[0].close()
      }
    },
    customRangeLabel (range) {
      // Removed the moment calculations here because they were behaving strangely.
      // By using setHours() we apply local/browser tz at start and end of selected day with no unwanted conversion
      if (range.greater_than && range.less_than) {
        const from = range.greater_than
        const to = range.less_than
        from.setHours(0, 0, 0, 0)
        to.setHours(23, 59, 59, 999)
        this.datepickerFrom = from
        this.datepickerTo = to

        return `${from.toDateString().substr(4)} - ${to.toDateString().substr(4)}`
      }
      return 'Custom'
    },
    dateSelected (datepickerType, val) {
      this[datepickerType] = val
    },
    applyFilterAction (val) {
      this.executeFilterAction(val)
      let updateField = 'completed'
      let updateValue = {}

      if (this.dateFilter.model === 'custom') {
        updateField = 'customRangeDate'
        updateValue = {
          greater_than: this.datepickerFrom,
          less_than: this.datepickerTo
        }
      } else if (this.dateFilter.model === 'allTime') {
        updateValue = {
          condition: 'not',
          value: null
        }
      } else {
        updateValue = {
          greater_than: this.datepickerFrom ? moment(this.datepickerFrom).unix() + this.getTZDiff() : null,
          less_than: this.datepickerTo ? moment(this.datepickerTo).unix() + this.getTZDiff() : null
        }
      }

      this.updateCurrentQuery({
        field: updateField,
        value: updateValue
      })
    },
    updateFilterValuesBasedOnResultsToInclude () {
      let destination = this.allResultsSelected ? 'completed' : 'program_discharge'

      if (this.allResultsSelected && this.analyticsCurrentQuery.program_discharge) {
        destination = 'completed'
      } else if (!this.allResultsSelected && this.analyticsCurrentQuery.completed) {
        destination = this.analyticsCurrentQuery.completed.greater_than === null || this.analyticsCurrentQuery.completed.less_than === null ? 'completed' : 'program_discharge'
      }

      this.updateCurrentQuery({ field: 'dateFilterMode', value: destination })
    },
    updateValuesFromQuery (savedQuery) {
      const data = Object.assign({}, savedQuery)
      this.$nextTick().then(() => {
        const hasDates = data.query.hasOwnProperty(this.dateFilter.filterName) // eslint-disable-line no-prototype-builtins
        const value = hasDates ? data.query[this.dateFilter.filterName] : 'lastMonth'
        this.computedFilters[0].model = value

        if (value === 'custom' && data.query.customRangeDate) {
          this.datepickerFrom = moment(data.query.customRangeDate.greater_than).tz(this.clientTime).toDate()
          this.datepickerTo = moment(data.query.customRangeDate.less_than).tz(this.clientTime).toDate()
        } else {
          this.setDatepickerValues(value)
        }

        this.applyFilterAction(true)
      })
    },
    setDateFilterItems () {
      this.setAnalyticsFilterItems(this.dateFilter)
    },
    setDatepickerValues () {
      switch (this.dateFilter.model) {
        case 'lastMonth':
          this.datepickerFrom = START_OF_LAST_MONTH
          this.datepickerTo = END_OF_LAST_MONTH
          break

        case 'yearToDate':
          this.datepickerFrom = START_OF_YEAR
          this.datepickerTo = END_OF_PREVIOUS_DAY
          break

        case 'lastYear':
          this.datepickerFrom = START_OF_PREVIOUS_YEAR
          this.datepickerTo = END_OF_PREVIOUS_YEAR
          break

        case 'custom':
          this.datepickerFrom = this.analyticsCurrentQuery.customRangeDate && this.analyticsCurrentQuery.customRangeDate.greater_than
            ? moment(this.analyticsCurrentQuery.customRangeDate.greater_than).tz(this.clientTime).toDate()
            : this.datepickerFrom
              ? this.datepickerFrom
              : null

          this.datepickerTo = this.analyticsCurrentQuery.customRangeDate && this.analyticsCurrentQuery.customRangeDate.less_than
            ? moment(this.analyticsCurrentQuery.customRangeDate.less_than).tz(this.clientTime).toDate()
            : this.datepickerTo
              ? this.datepickerTo
              : null
          break

        // also "allTime"
        default:
          this.datepickerFrom = null
          this.datepickerTo = null
          break
      }
    },
    cancelChanges () {
      this.cancelModelChange(this.dateFilter, this.analyticsCurrentQuery[this.dateFilter.filterName])
      this.setDatepickerValues()
    }
  }
}
</script>

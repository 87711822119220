<template>
  <AnalyticsFilter
    :filters="computedFilters"
    :custom-button-text="customButtonText"
    :before-execute="beforeExecute"
    :error="errorMessage"
    :show-error="showError"
    @executeFilter="executeFilterAction(false)"
    @resetModel="cancelChanges"
  >
    <template
      v-if="filter.filterKey === 'ageFilter'"
      slot="title"
      slot-scope="{ filter, selectNone }"
    >
      <div class="filter-title">
        <h5>{{ filter.title }}</h5>
        <div>
          <button
            class="btn btn-bare"
            @click="selectAllAges(filter)"
          >
            {{ $t('all') }}
          </button>
          <span>|</span>
          <button
            class="btn btn-bare"
            @click="selectNone(filter)"
          >
            {{ $t('none') }}
          </button>
        </div>
      </div>
    </template>
  </AnalyticsFilter>
</template>

<script>
import AnalyticsFilter from '@/components/analytics/filters/AnalyticsFilter'
import { AnalyticsFilterHelpers } from '@/mixins/analytics/AnalyticsFilterHelpers'
import * as GENDER_VALUES from '@/mixins/constants/GenderValues.js'
import { componentRefreshSingleton } from '@/mixins/ComponentRefresh/ComponentRefreshSingleton'

export default {
  name: 'AnalyticsPatientsFilter',
  components: { AnalyticsFilter },
  mixins: [AnalyticsFilterHelpers],
  data () {
    return {
      selectedGender: [],
      selectedAge: [],
      isAndValidation: true,
      genderFilter: {
        title: this.$t('gender'),
        type: 'checkbox',
        filterName: 'gender',
        filterKey: 'genderFilter',
        selectedKey: 'selectedGender',
        label: 'name',
        model: [],
        hardcodedItems: true,
        required: true,
        items: [
          { label: this.$t(GENDER_VALUES.GENDER_LOCALE_MALE), value: GENDER_VALUES.GENDER_MALE, initSelected: true },
          { label: this.$t(GENDER_VALUES.GENDER_LOCALE_FEMALE), value: GENDER_VALUES.GENDER_FEMALE, initSelected: true },
          { label: this.$t(GENDER_VALUES.GENDER_LOCALE_UNKNOWN), value: GENDER_VALUES.GENDER_UNKNOWN, initSelected: true },
          { label: this.$t(GENDER_VALUES.GENDER_LOCALE_NON_BINARY), value: GENDER_VALUES.GENDER_NON_BINARY, initSelected: true },
          { label: this.$t(GENDER_VALUES.GENDER_LOCALE_OTHER), value: GENDER_VALUES.GENDER_OTHER, initSelected: true }

        ]
      },
      ageFilter: {
        title: this.$t('age'),
        type: 'checkbox',
        filterName: 'age',
        filterKey: 'ageFilter',
        selectedKey: 'selectedAge',
        label: 'name',
        model: [],
        hardcodedItems: true,
        required: true,
        items: [
          { label: this.$t('ages.lt18'), value: '0_17', initSelected: true },
          { label: this.$t('ages.18_25'), value: '18_25', initSelected: true },
          { label: this.$t('ages.26_35'), value: '26_35', initSelected: true },
          { label: this.$t('ages.36_45'), value: '36_45', initSelected: true },
          { label: this.$t('ages.46_55'), value: '46_55', initSelected: true },
          { label: this.$t('ages.56_65'), value: '56_65', initSelected: true },
          { label: this.$t('ages.gt65'), value: '66_0', initSelected: true },
          { label: this.$t('ages.custom'), value: 'custom', customInputComponent: 'CustomRangeAge' }
        ]
      }
    }
  },
  computed: {
    allPatientsSelected () {
      return this.computedFilters.every(filter => this[filter.selectedKey].length === filter.items.filter(i => i.value !== 'custom').length)
    },
    customButtonText () {
      return this.selectedGender.length || this.selectedAge.length ? this.allPatientsSelected ? this.$t('allPatientsLabel') : this.$t('somePatients') : this.$t('noPatients')
    },
    computedFilters () {
      return [this.genderFilter, this.ageFilter]
    },
    errorMessage () {
      const genderFilterCantContinue = !(this.singleFilterCanContinue(this.genderFilter))
      const ageFilterCantContinue = !(this.singleFilterCanContinue(this.ageFilter))
      const customAgeFilterCantContinue = !(this.singleFilterCanContinue(this.ageFilter))
      if (genderFilterCantContinue && ageFilterCantContinue) {
        return this.$t('atLeast1GenderAndAgeRange')
      }
      if (genderFilterCantContinue) {
        return this.$t('atLeast1Gender')
      }
      if (customAgeFilterCantContinue && this.errorType === 'custom_empty') {
        return this.$t('atLeast1CustomAgeRange')
      }
      if (ageFilterCantContinue) {
        return this.$t('atLeast1AgeRange')
      }
      return ''
    }
  },
  created () {
    this.setGenderFilterItems()
    this.setAgeFilterItems()
  },
  methods: {
    updateValuesFromQuery (savedQuery) {
      const data = Object.assign({}, savedQuery)
      const hasGenderFilter = data.query.hasOwnProperty('gender') // eslint-disable-line no-prototype-builtins
      this.computedFilters[0].model = hasGenderFilter ? data.query.gender : []
      const hasAgeFilter = data.query.hasOwnProperty('age') // eslint-disable-line no-prototype-builtins

      // Hack to be able to select "custom age range"
      if (data.query.hasOwnProperty('customAgeRange')) { // eslint-disable-line no-prototype-builtins
        const index = data.query.age.length - 1
        data.query.age[index] = 'custom'
      }

      // Set Age Values selected.
      this.computedFilters[1].model = hasAgeFilter ? data.query.age : []
      componentRefreshSingleton.refreshComponentViewByName('CustomRangeAge', savedQuery)
      this.executeFilterAction(true)
    },
    customAgeMapper (v) {
      let r = null
      if (typeof v === 'string' && v !== 'custom') {
        r = {}
        const arr = v.split('_')
        const greaterThan = parseInt(arr[0])
        const lessThan = parseInt(arr[1])
        if (greaterThan) {
          r.greater_than = greaterThan
        }
        if (lessThan) {
          r.less_than = lessThan
        }
      }
      return !r ? v : r
    },
    setGenderFilterItems () {
      this.setAnalyticsFilterItems(this.genderFilter)
    },
    selectAllAges (filter) {
      // OWL-8136 - select all but exclude custom as it will be covered by the other checkboxes
      filter.model = filter.items.filter(i => i.value !== 'custom').map(i => i.value)
    },
    setAgeFilterItems () {
      this.setAnalyticsFilterItems(this.ageFilter)
    },
    cancelChanges () {
      this.cancelModelChange(this.genderFilter, this.analyticsCurrentQuery.gender)
      this.cancelModelChange(this.ageFilter, this.analyticsCurrentQuery.age)
    }
  }
}
</script>

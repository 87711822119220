<template>
  <div>
    <AnalyticsResultChartsOutcomes
      v-if="inpatientQuery"
      :key="`outcomes_${source.queryTimestamp}`"
      :name="source.name"
      :data="source.outcomes"
      :patient-type="source.patientType"
    />
    <AnalyticsResultChartsOutcomesOutpatient
      v-if="!inpatientQuery"
      :key="`outcomes_${source.queryTimestamp}`"
      :name="source.name"
      :data="source.outcomesOutpatient"
      :patient-type="source.patientType"
    />
    <AnalyticsResultChartsPatientStatus
      v-if="inpatientQuery"
      :key="`patientStatus_${source.queryTimestamp}`"
      :name="source.name"
      :data="source.patientStatus"
    />
    <AnalyticsResultChartsPatientStatusOutpatient
      v-if="!inpatientQuery"
      :key="`patientStatusOutpatient_${source.queryTimestamp}`"
      :name="source.name"
      :data="source.patientStatusOutpatient"
    />
    <AnalyticsResultChartsDemographics
      :key="`demographics_${source.queryTimestamp}`"
      :name="source.name"
      :data="source.demographics"
    />
  </div>
</template>

<script>
import AnalyticsResultChartsOutcomes from '@/components/analytics/outcomesExplorer/charts/outcomes/AnalyticsResultChartsOutcomes'
import AnalyticsResultChartsOutcomesOutpatient from '@/components/analytics/outcomesExplorer/charts/outcomes/AnalyticsResultChartsOutcomesOutpatient'
import AnalyticsResultChartsDemographics from '@/components/analytics/outcomesExplorer/charts/demographics/AnalyticsResultChartsDemographics'
import AnalyticsResultChartsPatientStatus from '@/components/analytics/outcomesExplorer/charts/patientStatus/AnalyticsResultChartsPatientStatus'
import AnalyticsResultChartsPatientStatusOutpatient from '@/components/analytics/outcomesExplorer/charts/patientStatus/AnalyticsResultChartsPatientStatusOutpatient'

export default {
  name: 'AnalyticsResultCharts',
  components: {
    AnalyticsResultChartsOutcomes,
    AnalyticsResultChartsOutcomesOutpatient,
    AnalyticsResultChartsDemographics,
    AnalyticsResultChartsPatientStatus,
    AnalyticsResultChartsPatientStatusOutpatient
  },
  props: {
    source: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  computed: {
    inpatientQuery () {
      return this.source.patientType === 'inpatient'
    }
  }
}
</script>

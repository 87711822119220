<template>
  <div class="analytics-data-table">
    <LoadingSpinner
      v-if="isLoading"
      :custom-text="$t('recordsBeingGenerated')"
      class="text-center py-4"
    />
    <vue-good-table
      v-else
      :total-rows="totalRecords"
      :columns="filteredColumns"
      :is-loading="isLoading"
      :rows="tableRows"
      mode="remote"
      style-class="vgt-table table-bordered"
      :pagination-options="{
        enabled: true
      }"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
    >
      <div
        v-show="isLoading"
        slot="loadingContent"
      >
        <LoadingSpinner :custom-text="$t('recordsBeingGenerated')" />
      </div>
      <div
        v-show="!isLoading"
        slot="emptystate"
      >
        {{ $t('analyticsTableNoRecords') }}
      </div>
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span
          v-if="props.column.isMeasureQuestionItem"
          v-tooltip="{content: props.column.label, classes: ['table-header-with-tooltip'], placement: 'top-start'}"
        >
          {{ props.column.label }}
        </span>
        <span v-else>
          {{ props.column.label }}
        </span>
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <AnalyticsDataTableItem
          :props="props"
          :hideprogram-fields="hideProgramFields"
          :hide-location-fields="hideLocationFields"
        />
      </template>
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <PaginationFooter
          v-show="paginationEnabled && !isLoading"
          :total="props.total"
          :server-params="serverParams"
          :per-page-changed="props.perPageChanged"
          :page-changed="onPageChange"
        />
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { RemoteTable } from '@/mixins/remoteTables/RemoteTable'
import { VueGoodTable } from 'vue-good-table'
import LoadingSpinner from '@/components/common/LoadingSpinner'
import AnalyticsDataTableItem from '@/components/analytics/outcomesExplorer/AnalyticsDataTableItem'
import PaginationFooter from '@/components/common/PaginationFooter'
import { AnalyticsRecordsData } from '@/mixins/TableColumnsHelper'
import { ANALYTICS_GET_RECORDS } from '@/store/modules/analytics/outcomes-explorer/constants.js'
import { mapGetters } from 'vuex'

export default {
  name: 'AnalyticsDataTable',
  components: {
    AnalyticsDataTableItem,
    PaginationFooter,
    LoadingSpinner,
    VueGoodTable
  },
  mixins: [RemoteTable, AnalyticsRecordsData],
  data () {
    return {
      noStandarRemoteTable: true,
      defaultSortOrder: 'desc',
      defaultSortField: 'completed',
      action: ANALYTICS_GET_RECORDS
    }
  },
  computed: {
    ...mapGetters({
      currentQuery: 'getAnalyticsCurrentQuery',
      recordsData: 'getAnalyticsRecordsData',
      customParams: 'getAnalyticsApiQuery'
    }),
    tableRows () {
      return this.isLoading ? [] : this.recordsData
    },
    filteredColumns () {
      return this.columns.filter(c => !c.hide)
    }
  },
  created () {
    this.refreshView()
  },
  methods: {
    refreshView () {
      this.loadItems(true)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

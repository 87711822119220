<template>
  <div
    v-if="Object.keys(data).length"
    slot="content"
  >
    <h4>{{ sectionTitle }}</h4>
    <div
      v-for="(d, index) in data"
      :key="index"
      class="row"
    >
      <div class="col-sm-6 d-flex">
        <AnalyticsResultChartsDemographicsGender
          :program-name="name"
          :data="d.genderBreakdown"
        />
      </div>
      <div class="col-sm-6 d-flex">
        <AnalyticsResultChartsDemographicsAge
          :program-name="name"
          :data="d.ageRange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AnalyticsResultChartsDemographicsGender from '@/components/analytics/outcomesExplorer/charts/demographics/AnalyticsResultChartsDemographicsGender'
import AnalyticsResultChartsDemographicsAge from '@/components/analytics/outcomesExplorer/charts/demographics/AnalyticsResultChartsDemographicsAge'

export default {
  name: 'AnalyticsResultChartsDemographics',
  components: {
    AnalyticsResultChartsDemographicsAge,
    AnalyticsResultChartsDemographicsGender
  },
  props: ['name', 'data'],
  computed: {
    sectionTitle () {
      return this.$t('programDemographicsTitle')
    }
  }
}
</script>

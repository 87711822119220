<template>
  <div
    v-if="Object.keys(data).length"
    slot="content"
  >
    <h4>{{ sectionTitle }}</h4>
    <div
      v-for="(d, index) in data"
      :key="index"
    >
      <AnalyticsResultChartsPatientStatusOutpatientMaxLOT
        :program-name="name"
        :data="d.maxLot"
      />
    </div>
  </div>
</template>

<script>
import AnalyticsResultChartsPatientStatusOutpatientMaxLOT from '@/components/analytics/outcomesExplorer/charts/patientStatus/AnalyticsResultChartsPatientStatusOutpatientMaxLOT'

export default {
  name: 'AnalyticsResultChartsPatientStatusOutpatient',
  components: {
    AnalyticsResultChartsPatientStatusOutpatientMaxLOT
  },
  props: ['name', 'data'],
  computed: {
    sectionTitle () {
      return this.$t('programPatientStatusTitle')
    }
  }
}
</script>

<template>
  <AnalyticsChartWrapper
    ref="chartWrapper"
    :config="data"
    @titleChanged="changeHighChartsTitle"
    @captionChanged="changeHighChartsCaption"
  >
    <template slot="chart">
      <PieChart
        ref="chartRef"
        :chart="data"
        @adjustMenuIcon="adjustMenuIcon"
      />
    </template>
  </AnalyticsChartWrapper>
</template>

<script>
import AnalyticsChartWrapper from '@/components/analytics/outcomesExplorer/charts/AnalyticsChartWrapper'
import PieChart from '@/components/common/PieChart'
import { ChartChangesHelper } from '@/mixins/analytics/Charts/ChartChangesHelper'

export default {
  name: 'AnalyticsResultChartsPatientStatusDischargeDisposition',
  components: {
    AnalyticsChartWrapper,
    PieChart
  },
  mixins: [ChartChangesHelper],
  props: {
    programName: {
      type: String,
      default: ''
    },
    data: {}
  }
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <div
    v-if="Object.keys(data).length"
    slot="content"
  >
    <h4>{{ sectionTitle }}</h4>
    <div
      v-for="(d, index) in data"
      :key="index"
      class="row"
    >
      <div class="col-sm-6 d-flex">
        <AnalyticsResultChartsPatientStatusDischargeDisposition
          :program-name="name"
          :data="d.dischargeDisposition"
        />
      </div>
      <div class="col-sm-6 d-flex">
        <AnalyticsResultChartsPatientStatusDaysFromAdmissionToDischarge
          :program-name="name"
          :data="d.daysFromAdmissionToDischargeAssessment"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AnalyticsResultChartsPatientStatusDischargeDisposition from '@/components/analytics/outcomesExplorer/charts/patientStatus/AnalyticsResultChartsPatientStatusDischargeDisposition'
import AnalyticsResultChartsPatientStatusDaysFromAdmissionToDischarge from '@/components/analytics/outcomesExplorer/charts/patientStatus/AnalyticsResultChartsPatientStatusDaysFromAdmissionToDischarge'

export default {
  name: 'AnalyticsResultChartsPatientStatus',
  components: {
    AnalyticsResultChartsPatientStatusDischargeDisposition,
    AnalyticsResultChartsPatientStatusDaysFromAdmissionToDischarge
  },
  props: ['name', 'data'],
  computed: {
    sectionTitle () {
      return this.$t('programPatientStatusTitle')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

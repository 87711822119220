<template>
  <CustomCheckbox
    :id="`id_${filter.filterName+index}`"
    v-model="filter.model"
    :name="`label_${filter.filterName}`"
    :hide-checkbox="filter.hideCheckbox"
    :label="item.label"
    :val="item.value"
    class="measure-filter-item pointer py-1"
    :class="{'row-active': filter.model.includes(item.value)}"
  >
    <template slot="customLabel">
      <i
        v-if="filter.model.includes(item.value)"
        class="fa fa-check color-success"
      />
      <span class="measure-abbreviation"> {{ measure.abbreviation }}</span>
      <span class="measure-name">{{ measure.name }}</span>
      <span class="measure-conditions">
        <span
          v-for="condition in measure.measure_condition"
          :key="condition.id"
          class="badge rounded-pill"
        >
          {{ condition.condition_text }}
        </span>
      </span>
    </template>
  </CustomCheckbox>
</template>

<script>
import CustomCheckbox from '@/components/common/CustomCheckbox'
export default {
  name: 'AnalyticsMeasuresFilterItem',
  components: { CustomCheckbox },
  props: ['item', 'filter', 'index'], // eslint-disable-line vue/require-prop-types
  computed: {
    measure () {
      return this.item.rawData
    }
  }
}
</script>

<template>
  <div class="analytics-outcomes-explorer">
    <div class="analytics-outcomes-explorer-controls mb-4">
      <button
        class="btn btn-secondary mr-2"
        @click="startNewQuery"
      >
        {{ $t('newQuery') }}...
      </button>
      <AnalyticsLoadSavedQueries @loadQuery="loadQuery" />
    </div>

    <div
      v-show="showQueryBuilder"
      class="analytics-filters-container"
    >
      <div class="analytics-filters-header mb-2">
        <span v-if="analyticsSavedQueryLoaded">{{ analyticsSavedQueryLoaded.name }}</span>
        <span v-else>{{ $t('newQuery') }}</span>
      </div>
      <div class="analytics-filters-controls">
        <div class="float-left">
          <AnalyticsProgramsLocationsFilter
            ref="analyticsProgramFilter"
            @programsSelected="triggerProgramSelected"
            @manualChange="manualChange"
          />
        </div>
        <AnalyticsClinicianAndTeam
          v-if="selectedPrograms.length && programsLocationFilterManuallyStarted"
          ref="analyticsClinicianAndTeamFilter"
          @filterChanged="filterChanged"
        />
        <AnalyticsMeasuresFilter
          v-if="selectedPrograms.length && programsLocationFilterManuallyStarted"
          ref="analyticsMeasuresFilter"
          @filterChanged="filterChanged"
        />
        <AnalyticsPatientsFilter
          v-if="selectedPrograms.length && programsLocationFilterManuallyStarted"
          ref="AnalyticsPatientsFilter"
          @filterChanged="filterChanged"
        />
        <AnalyticsDate
          v-if="selectedPrograms.length && programsLocationFilterManuallyStarted"
          ref="AnalyticsDate"
          :current-query-completed-type="analyticsCurrentQuery.completedType"
          :current-query-custom-range="analyticsCurrentQuery.customRangeDate"
          @filterChanged="filterChanged"
        />
        <div
          v-if="selectedPrograms.length && programsLocationFilterManuallyStarted"
          class="last-step"
        >
          <div class="total-records-container">
            <div class="btn-group">
              <button
                type="button"
                :disabled="analyticsRecordsLoading"
                class="btn btn-secondary go-button"
                @click="getRecords"
              >
                {{ $t('go') }}
              </button>
              <button
                type="button"
                :disabled="analyticsRecordsLoading"
                class="btn btn-sm dropdown-toggle btn-secondary dropdown-toggle-split"
                @click="openSubMenu"
              />

              <div
                class="dropdown-menu owl-menu"
                :class="{show: opened}"
              >
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="openSaveQueryForm"
                >Save Query...</a>
              </div>
            </div>
            <div class="total-records">
              <template v-if="!totalRecordsLoading">
                <p class="number">
                  {{ Number(totalRecords).toLocaleString() }}
                </p>
                <p class="text">
                  {{ $t('records') }}
                  <icon-button
                    v-if="totalRecords > 10000"
                    class="fa-exclamation-triangle"
                    :tooltip-data="tooltipData"
                    :status="true"
                    :is-warning="true"
                    :close-on-mouse-outside="true"
                    @changed="showTooltip = !showTooltip"
                    @close="showTooltip = false"
                  />
                </p>
              </template>
              <LoadingSpinner
                v-else
                :no-text="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <AnalyticsOutcomesExplorerResults
      v-if="selectedPrograms.length && recordsDataQuery && programsLocationFilterManuallyStarted"
      ref="AnalyticsOutcomesExplorerResults"
      :query="recordsDataQuery"
      :total-records="totalRecords"
    />
  </div>
</template>

<script>
import AnalyticsProgramsLocationsFilter from '@/components/analytics/filters/AnalyticsProgramsLocationsFilter'
import AnalyticsClinicianAndTeam from '@/components/analytics/filters/AnalyticsClinicianAndTeam'
import AnalyticsMeasuresFilter from '@/components/analytics/filters/AnalyticsMeasuresFilter'
import AnalyticsPatientsFilter from '@/components/analytics/filters/AnalyticsPatientsFilter'
import AnalyticsOutcomesExplorerResults from '@/components/analytics/outcomesExplorer/AnalyticsOutcomesExplorerResults'
import AnalyticsLoadSavedQueries from '@/components/analytics/savedQueries/AnalyticsLoadSavedQueries'
import AnalyticsDate from '@/components/analytics/filters/AnalyticsDate'
import LoadingSpinner from '@/components/common/LoadingSpinner'
import IconButton from '@/components/common/IconButton'
import moment from 'moment'
import {
  ANALYTICS_SELECT_PROGRAMS,
  ANALYTICS_GET_TOTAL_RECORDS,
  ANALYTICS_SAVED_QUERY_LOADED,
  ANALYTICS_SET_SELECTED_QUERY_FOR_EDITION,
  ANALYTICS_RESET_SELECTED_QUERY_FOR_EDITION,
  ANALYTICS_API_QUERY
} from '@/store/modules/analytics/outcomes-explorer/constants.js'
import { mapMutations, mapGetters } from 'vuex'
import { Helpers } from '@/mixins/Helpers'
import {
  MUTATION_SET_MODAL_CONFIG,
  MUTATION_SET_MODAL_OPENED,
  MUTATION_SET_MODAL_TEMPLATE
} from '@/store/modules/applicationState/constants'
import { ModalMixin } from '@/mixins/modal/ModalMixin'

export default {
  name: 'AnalyticsOutcomesExplorer',
  components: {
    AnalyticsClinicianAndTeam,
    AnalyticsDate,
    AnalyticsLoadSavedQueries,
    AnalyticsMeasuresFilter,
    AnalyticsOutcomesExplorerResults,
    AnalyticsPatientsFilter,
    AnalyticsProgramsLocationsFilter,
    IconButton,
    LoadingSpinner
  },
  mixins: [Helpers, ModalMixin],
  data () {
    return {
      programsLocationFilterManuallyStarted: false,
      showQueryBuilder: false,
      recordsDataQuery: null,
      opened: false,
      showTooltip: false,
      filterNames: ['analyticsClinicianAndTeamFilter', 'analyticsMeasuresFilter', 'AnalyticsPatientsFilter', 'AnalyticsDate']
    }
  },
  computed: {
    ...mapGetters({
      totalRecords: 'getAnalyticsTotalRecords',
      totalRecordsLoading: 'getAnalyticsTotalRecordsLoading',
      analyticsRecordsLoading: 'getAnalyticsRecordsLoading',
      selectedPrograms: 'getAnalyticsProgramsSelected',
      analyticsSavedQueryLoaded: 'getAnalyticsSavedQueryLoaded',
      analyticsCurrentQuery: 'getAnalyticsCurrentQuery'
    }),
    tooltipData () {
      return {
        content: this.$t('fileGenerationWarning'),
        classes: ['exports-warning-tooltip'],
        placement: 'bottom',
        trigger: 'manual',
        show: this.showTooltip
      }
    }
  },
  watch: {
    selectedPrograms (n) {
      if (!n || (n && (!n.length))) {
        this.resetTotalRecords(null)
      }
    }
  },
  beforeDestroy () {
    this.setAnalyticsSavedQueryLoaded(null)
    this.close()
  },
  methods: {
    ...mapMutations({
      setModalOpened: MUTATION_SET_MODAL_OPENED,
      setModalTemplate: MUTATION_SET_MODAL_TEMPLATE,
      setCustomModalConfig: MUTATION_SET_MODAL_CONFIG,
      programsSelected: ANALYTICS_SELECT_PROGRAMS,
      resetTotalRecords: ANALYTICS_GET_TOTAL_RECORDS,
      setAnalyticsSavedQueryLoaded: ANALYTICS_SAVED_QUERY_LOADED,
      setSelectedQueryForEdition: ANALYTICS_SET_SELECTED_QUERY_FOR_EDITION,
      resetSelectedQueryForEdition: ANALYTICS_RESET_SELECTED_QUERY_FOR_EDITION,
      setAnalyticsQuery: ANALYTICS_API_QUERY
    }),
    makeDate (value) {
      return moment.unix(value).toDate()
    },
    manualChange () {
      this.programsLocationFilterManuallyStarted = true
    },
    triggerProgramSelected (programs) {
      // Changing programs, needs to reset measure search input to avoid blank if same measure not in new list
      if (this.$refs.analyticsMeasuresFilter) {
        this.$refs.analyticsMeasuresFilter.resetMeasuresFilterHeaderFilters()
      }
      this.programsSelected(programs)
      this.$nextTick().then(() => {
        if (!programs.length) {
          this.recordsDataQuery = null
        }
        this.triggerActionAllFilters('resetToDefaultSelection')
        this.filterChanged()
      })
    },
    triggerActionAllFilters (actionName, params) {
      this.filterNames.forEach(filterName => {
        const ref = this.$refs[filterName]
        if (ref && typeof ref[actionName] === 'function') {
          ref[actionName](params)
        }
      })
    },
    startNewQuery () {
      this.showQueryBuilder = true
      this.setAnalyticsSavedQueryLoaded(null)
      this.resetSelectedQueryForEdition()

      this.$nextTick().then(() => {
        if (this.$refs.analyticsProgramFilter) {
          this.$refs.analyticsProgramFilter.resetFilter()
          this.$refs.analyticsProgramFilter.open()
        }
      })
    },
    loadQuery (savedQuery) {
      this.showQueryBuilder = true
      this.manualChange()
      const copy = this.copyObj(savedQuery)
      this.setAnalyticsQuery(savedQuery)
      this.setAnalyticsSavedQueryLoaded(savedQuery)
      this.$refs.analyticsProgramFilter.updateValuesFromQuery(copy).then(() => {
        this.$nextTick().then(() => {
          this.triggerActionAllFilters('updateValuesFromQuery', copy)
          this.filterChanged()
        })
      })
    },
    openSubMenu () {
      this.opened = !this.opened
    },
    openSaveQueryForm () {
      this.opened = false
      if (this.analyticsSavedQueryLoaded) {
        this.setSelectedQueryForEdition(this.analyticsSavedQueryLoaded)
      }
      this.setCustomModalConfig({
        action: 'create',
        customClass: 'analytics-save-query-modal'
      })
      this.setModalTemplate('AnalyticsQueryFormModal')
      this.setModalOpened(true)
    },
    getQueryForApi () {
      const criteria = { ...this.analyticsCurrentQuery }
      if ((criteria.program_uuid && criteria.program_uuid.length) || (criteria.location_uuid && criteria.location_uuid.length)) {
        if (this.$refs.AnalyticsPatientsFilter) {
          const ageMapper = this.$refs.AnalyticsPatientsFilter.customAgeMapper
          criteria.age = criteria.age.filter(v => v !== 'custom').map(v => ageMapper(v))
        }

        // ToDo - Verify this
        // if (criteria.program_discharge) {
        //   criteria.program_discharge = this.getDateRangeFromLabel(criteria.program_discharge) // this.$refs.AnalyticsDate.customDateMapper(criteria.program_discharge)
        // }
        if (Object.prototype.hasOwnProperty.call(criteria, 'program_discharge') && criteria.program_discharge === null && criteria.completed === undefined) {
          delete criteria.program_discharge
        }

        if (criteria && criteria.hasOwnProperty('results_to_include') && criteria.results_to_include === 'admit_discharge') { // eslint-disable-line no-prototype-builtins
          criteria.program_admission = {
            condition: 'not',
            value: null
          }
          criteria.program_discharge = {
            condition: 'not',
            value: null
          }
          criteria.response_type = ['admission', 'discharge']
        }

        if (criteria && criteria.clinical_teams && criteria.clinical_teams.length === 0) {
          delete criteria.clinical_teams
        }

        return { criteria }
      }
      return null
    },
    getRecords () {
      this.recordsDataQuery = this.getQueryForApi()
      this.setAnalyticsQuery(this.recordsDataQuery)
      if (this.$refs.AnalyticsOutcomesExplorerResults) {
        this.$refs.AnalyticsOutcomesExplorerResults.refreshView()
      }
    },
    filterChanged () {
      this.$nextTick().then(() => {
        this.$store.dispatch('CANCEL_AXIOS_REQUEST', { requestIdentifier: 'analyticsGetTotalRecords' })
        const query = this.getQueryForApi()
        if (query) {
          this.$genericDispatch(ANALYTICS_GET_TOTAL_RECORDS, query, true)
        }
      })
    }
  }
}
</script>

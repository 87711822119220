<template>
  <div
    class="analytics-table-header-actions"
    @click="toggleExportOptionsOpened"
  >
    <label
      class="btn btn-sm btn-outline-secondary large-button"
      :disabled="recordsLoading"
    > {{ $t('analyticsTableActionButtons.export') }}
      <i
        class="fas filter-toggle pointer"
        :class="{'fa-caret-down': !opened, 'fa-caret-up': opened}"
      />
    </label>
    <div class="dropdown">
      <div
        class="dropdown-menu dropdown-menu-export-options"
        :class="{show: opened}"
      >
        <div
          class="dropdown-option"
          @click="exportAsCSV"
        >
          <span class="dropdown-option-text"> {{ $t('analyticsTableActionButtons.csv') }} </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { CREATE_EXPORT, GET_EXPORTS, SHOW_EXPORT_TOOLTIP } from '@/store/modules/exports/constants.js'
import { Helpers } from '@/mixins/Helpers'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import moment from 'moment'

export default {
  name: 'AnalyticsDataTableHeaderActions',
  mixins: [Helpers, DateTimeHelper],
  data () {
    return {
      opened: false
    }
  },
  computed: {
    ...mapGetters({
      query: 'getAnalyticsApiQuery',
      recordsLoading: 'getAnalyticsRecordsLoading',
      gSettings: 'generalSettings'
    })
  },
  created () {
    window.addEventListener('click', (e) => {
      if (!this.$el.contains(e.target)) {
        this.opened = false
      }
    })
  },
  methods: {
    ...mapActions({
      showExportTooltip: SHOW_EXPORT_TOOLTIP,
      createExport: CREATE_EXPORT
    }),
    toggleExportOptionsOpened () {
      if (!this.recordsLoading) {
        this.opened = !this.opened
      }
    },
    getFilename () {
      const prefix = 'OWL'
      return `${prefix}-${moment().format('MMMDD-YYYY-hh:mm:ssA')}`
    },
    getDatesInUnixUTC (range) {
      if (range || !Object.values(range).length) {
        return range
      }
      if (range.greater_than) {
        range.greater_than = this.$fromLocalToUTCAndGetUnix(range.greater_than)
      }
      if (range.less_than) {
        range.less_than = this.$fromLocalToUTCAndGetUnix(range.less_than)
      }
      return range
    },
    exportQuery (format) {
      this.showExportTooltip()
      const exportQuery = this.copyObj(this.query)
      if (!exportQuery.export) {
        exportQuery.export = {}
      }
      exportQuery.export.format = format
      exportQuery.export.filename = this.getFilename()
      exportQuery.timezone = this.gSettings.client_time_zone || moment.tz.guess()
      if (exportQuery.criteria.completed) {
        exportQuery.criteria.completed = this.getDatesInUnixUTC(exportQuery.criteria.completed)
      }
      if (exportQuery.criteria.program_discharge) {
        exportQuery.criteria.program_discharge = this.getDatesInUnixUTC(exportQuery.criteria.program_discharge)
      }
      this.createExport(exportQuery).then(() => {
        this.$genericDispatch(GET_EXPORTS, null, true)
      })
    },
    exportAsCSV () {
      this.exportQuery('csv')
    }
  }
}
</script>
<style lang="scss" scoped>
  .analytics-table-header-actions {
    // label, overide default style because it's used in template here outside a form element context
    // and was causing height and alignment issues in parent component
    label {margin-bottom: 0;}
    .large-button {
      width: 117px;
      height: 28px;
    }
    // .dropdown-menu in template was inheritting a margin-top value that caused it not to align
    // with it's trigger
    .dropdown-menu {
      margin-top: 0;
    }
    .dropdown-menu-export-options {
      // remove negative margins unless strictly necessary. prevents overlaps that can capture click events
      // and normalizes calculated heights
      // removed `padding: 1px;` because...
      top: 0;
      border: 1px solid;
      left: 3px;
      font-size: 16px;
      min-width: 100px;
      .dropdown-option {
        width: 107px;
        height: 40px;
        padding: 10px;
      }
      .dropdown-option:hover {
        background-color: rgba(223, 250, 247, 1);
        cursor: pointer;
      }
    }
  }
</style>

import orderBy from 'lodash/orderBy'

/**
   * filter the 'programsFiltered' array to remove service lines/ measures that are not selected
   */
export const setChartData = (programs, selectedServiceLines, selectedChartItems) => {
  if (Object.keys(programs).length === 0) {
    return []
  }

  let collection = []
  const collectionDemographics = {}
  const collectionPatientStatus = {}
  const collectionPatientStatusOutpatient = {}
  const collectionOutcomes = {}
  const programList = orderBy(programs, 'name')
  const patientType = programList[0].patientType
  const demographics = programList.map(item => [{ uuid: item.uuid, demographics: item.demographics }])
  const patientStatus = programList.map(item => [{ uuid: item.uuid, patientStatus: item.patientStatus }])
  const patientStatusOutpatient = programList.map(item => [{ uuid: item.uuid, patientStatusOutpatient: item.patientStatusOutpatient }])
  const outcomes = patientType === 'inpatient' ? programList.map(item => [{ uuid: item.uuid, outcomes: item.outcomes }]) : programList.map(item => [{ uuid: item.uuid, outcomes: item.outcomesOutpatient }])

  // a lot of finagling here to get the outcomes in right order/structure
  outcomes.forEach(i => {
    const serviceLineFound = selectedServiceLines.includes(i[0].uuid)
    if (serviceLineFound) {
      i[0].outcomes.forEach(item => {
        const thisMeasureSelected = selectedChartItems.includes(item.measureUuid)
        if (thisMeasureSelected) {
          for (const j in item) {
            if (j !== 'measureUuid' && j !== 'measureAbbr') {
              const thisItem = item[j]
              const thisMeasureCaptured = Object.hasOwn(collectionOutcomes, item.measureUuid)
              if (!thisMeasureCaptured) {
                if (j.includes('outpatient')) {
                  thisItem.chartGroup = 'outcomesOutpatient'
                }
                collectionOutcomes[item.measureUuid] = {}
              }
              const thisChartCaptured = Object.hasOwn(collectionOutcomes[item.measureUuid], j)
              if (!thisChartCaptured) {
                collectionOutcomes[item.measureUuid][j] = []
              }
              collectionOutcomes[item.measureUuid][j].push(thisItem)
            }
          }
          collectionOutcomes[item.measureUuid].measureAbbr = item.measureAbbr
        }
      })
    }
  })

  const sortedCollectionOutcomes = orderBy(collectionOutcomes, ['measureAbbr', 'title'])

  if (selectedChartItems.includes('catchall-uuid-demographics')) {
    demographics.forEach((i, j) => {
      const found = selectedServiceLines.includes(i[0].uuid)
      if (found) {
        collectionDemographics[j] = i[0].demographics
      }
    })
  }

  if (selectedChartItems.includes('catchall-uuid-patient-status')) {
    patientStatus.forEach((i, j) => {
      const found = selectedServiceLines.includes(i[0].uuid)
      if (found) {
        collectionPatientStatus[j] = i[0].patientStatus
      }
    })

    if (patientType === 'outpatient') {
      patientStatusOutpatient.forEach((i, j) => {
        const found = selectedServiceLines.includes(i[0].uuid)
        if (found) {
          collectionPatientStatusOutpatient[j] = i[0].patientStatusOutpatient
        }
      })
    }
  }

  collection.push({
    outcomes: patientType === 'inpatient' ? sortedCollectionOutcomes : {},
    outcomesOutpatient: patientType === 'outpatient' ? sortedCollectionOutcomes : {},
    demographics: collectionDemographics,
    patientStatus: collectionPatientStatus,
    patientStatusOutpatient: collectionPatientStatusOutpatient,
    patientType: patientType,
    queryTimestamp: programList[0].queryTimestamp,
    dataKey: Math.random().toString().substring(2, 5)
  })

  if (!Object.keys(sortedCollectionOutcomes).length && !Object.keys(collectionDemographics).length && !Object.keys(collectionPatientStatus).length && !Object.keys(collectionPatientStatusOutpatient).length) {
    collection = []
  }

  return collection
}

<template>
  <div
    v-if="Object.keys(data).length"
    slot="content"
  >
    <div
      v-for="(d, index) in data"
      :key="`outer_${index}`"
    >
      <h4>{{ d.measureAbbr }} {{ sectionTitle }}</h4>
      <div
        v-for="(chart, i) in d.outpatientScoreOverTime"
        :key="`ops_${i}`"
      >
        <AnalyticsResultChartsOutcomesOutpatientScoreOverTime :data="chart" />
      </div>
      <div
        v-for="(chart, i) in d.outpatientWeeksToBelowCutoff"
        :key="`wbc_${i}`"
      >
        <AnalyticsResultChartsOutcomesOutpatientWeeksToBelowCutoff :data="chart" />
      </div>
    </div>
  </div>
</template>

<script>
import AnalyticsResultChartsOutcomesOutpatientScoreOverTime from '@/components/analytics/outcomesExplorer/charts/outcomes/AnalyticsResultChartsOutcomesOutpatientScoreOverTime'
import AnalyticsResultChartsOutcomesOutpatientWeeksToBelowCutoff from '@/components/analytics/outcomesExplorer/charts/outcomes/AnalyticsResultChartsOutcomesOutpatientWeeksToBelowCutoff'
export default {
  name: 'AnalyticsResultChartsOutcomes',
  components: {
    AnalyticsResultChartsOutcomesOutpatientScoreOverTime,
    AnalyticsResultChartsOutcomesOutpatientWeeksToBelowCutoff
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    sectionTitle () {
      return this.$t('programOutcomesTitle')
    }
  },
  dataHasOutpatientItems (data) {
    return Object.hasOwn(data, 'outpatientScoreOverTime') || Object.hasOwn(data, 'outpatientWeeksToBelowCutoff')
  }
}
</script>

<template>
  <div
    v-if="config"
    class="analytics-chart-wrapper"
  >
    <div class="analytics-chart-wrapper-header">
      <h3
        contenteditable="true"
        :class="{ truncate: isTruncated }"
        @input="handleTitleChanged"
        @keydown="handleTitleChanged"
        @paste="handleTitleChanged"
      >
        {{ titleModel }}
      </h3>
    </div>
    <div :class="{horizontal}">
      <slot name="chart" />
      <div
        class="footer"
        :class="{ fullwidth: horizontal }"
      >
        <textarea
          v-model="captionModel"
          :maxlength="captionMax"
          :placeholder="$t('optionalCaption')"
          :rows="rowCount"
          :cols="colCount"
          class="form-control"
          @input="changeCaption($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>

import { mapMutations, mapActions } from 'vuex'
import {
  ANALYTICS_CHANGE_CHART_ATTRIBUTE,
  ANALYTICS_UPDATE_CURRENT_QUERY_CHART_INFO
} from '@/store/modules/analytics/outcomes-explorer/constants'

export default {
  name: 'AnalyticsChartWrapper',
  props: {
    config: {
      type: Object,
      default: null
    },
    chartTitle: {
      type: String,
      default: ''
    },
    horizontal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      changeTimer: null,
      doneChangeTimer: 1000,
      captionModel: null,
      isTruncated: false,
      screenWidth: null,
      titleModel: null
    }
  },
  computed: {
    titleMax () {
      return this.horizontal ? 130 : 55
    },
    captionMax () {
      return this.horizontal ? 465 : 270
    },
    rowCount () {
      return this.horizontal ? 13 : 3
    },
    colCount () {
      return this.horizontal ? 28 : 100
    }
  },
  created () {
    if (this.config) {
      this.captionModel = this.config.caption
      this.titleModel = this.config.title
      this.config.horizontal = this.horizontal
    }
  },
  methods: {
    ...mapMutations({
      updateChartAttribute: ANALYTICS_CHANGE_CHART_ATTRIBUTE
    }),
    ...mapActions({
      updateSavedQueryCharts: ANALYTICS_UPDATE_CURRENT_QUERY_CHART_INFO
    }),
    startTimer (callback, data) {
      clearTimeout(this.changeTimer)
      this.changeTimer = setTimeout(() => {
        this[callback](data)
      }, this.doneChangeTimer)
    },
    adjustMenuIcon (el) {
      const xTranslation = this.horizontal ? 300 : 35
      const element = el.querySelector('.highcharts-contextbutton > .highcharts-button-box')
      const element2 = el.querySelector('.highcharts-contextbutton > path')
      element.setAttribute('transform', `translate(${xTranslation}, -25) rotate(90)`)
      element2.setAttribute('transform', `translate(${xTranslation}, -25) rotate(90)`)
    },
    handleTitleChanged (e) {
      const truncateLength = this.titleMax - 3
      this.isTruncated = e.target.innerText.length >= truncateLength
      if ((e.target.innerText.length >= this.titleMax && e.keyCode !== 8) || (e.keyCode === 13)) {
        e.preventDefault()
      }
      this.startTimer('changeTitle', e)
    },
    changeCaption (e) {
      // prevent exceeding the set height or length of textarea
      const lines = this.captionModel.split('\n')
      this.captionModel = this.limitLines(lines, this.rowCount, this.colCount)
      this.callUpdateChartAttribute('caption', this.captionModel)
      return this.$emit('captionChanged', this.captionModel)
    },
    changeTitle (e) {
      this.callUpdateChartAttribute('title', e.target.innerText)
      return this.$emit('titleChanged')
    },
    limitLines (lines, rows, spaces) {
      for (let i = 0; i < lines.length; i++) {
        if (lines[i].length <= this.colCount) continue
        let j = 0
        let space = this.colCount
        while (j++ <= spaces) {
          if (lines[i].charAt(j) === ' ') {
            space = j
          }
        }
        lines[i + 1] = lines[i].substring(space + 1) + (lines[i + 1] || '')
        lines[i] = lines[i].substring(0, space)
      }
      return lines.slice(0, rows).join('\n')
    },
    callUpdateChartAttribute (attribute, value) {
      const data = {
        attribute: attribute,
        newValue: value,
        programUUID: this.config.programUUID,
        alias: this.config.alias,
        id: this.config.id,
        chartGroup: this.config.chartGroup
      }
      this.updateChartAttribute(data)
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
  .analytics-chart-wrapper-header {
    h3 {
      &.truncate {
        &::after {
          content: '...'
        }
      }
    }
    h4 {
      font-size: 16px !important;
      text-align: center !important;
    }
  }

  .fullwidth {
    padding-left: 0 !important;
    .form-control {
      margin-top: 16px;
      padding-bottom: 12px;
      overflow: hidden;
      resize: none;
    }
  }
</style>

<template>
  <div class="analytics-outcomes-explorer-results">
    <div class="header-actions-group">
      <div
        class="btn-group btn-group-toggle table-chart-group"
        data-toggle="buttons"
      >
        <label
          v-for="view in views"
          :key="view.value"
          v-tooltip="tabButtonTooltip(view)"
          class="btn btn-sm btn-outline-secondary btn-tab-toggle"
          :class="{active: currentView.value === view.value && view.disabled === false}"
          :disabled="buttonDisabled(view)"
          @click="setCurrentView(view)"
        >
          <input
            :id="`option${view.value}`"
            type="radio"
            name="options"
            autocomplete="off"
          >
          {{ view.label }}
        </label>
      </div>
      <AnalyticsDataTableHeaderActions v-show="showTable" />
    </div>
    <div class="analytics-outcomes-explorer-results-views">
      <AnalyticsDataTable
        v-show="showTable"
        ref="AnalyticsDataTable"
      />
      <div v-if="chartsEnabled">
        <AnalyticsResultCharts
          v-show="showCharts"
          ref="AnalyticsResultCharts"
          :is-visible="showCharts"
          :total-records="totalRecords"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AnalyticsDataTable from '@/components/analytics/outcomesExplorer/AnalyticsDataTable'
import AnalyticsResultCharts from '@/components/analytics/outcomesExplorer/charts/AnalyticsResultCharts'
import AnalyticsDataTableHeaderActions from '@/components/analytics/outcomesExplorer/AnalyticsDataTableHeaderActions'
import { mapGetters } from 'vuex'

export default {
  name: 'AnalyticsOutcomesExplorerResults',
  components: {
    AnalyticsDataTable,
    AnalyticsResultCharts,
    AnalyticsDataTableHeaderActions
  },
  props: {
    query: {
      type: Object,
      default: () => {}
    },
    totalRecords: {
      type: Number,
      default: 0
    }
  },
  data () {
    const views = [
      {
        label: this.$t('table'),
        value: 'table',
        component: 'AnalyticsDataTable',
        disabled: false
      },
      {
        label: this.$t('charts'),
        value: 'charts',
        component: 'AnalyticsResultCharts',
        disabled: false
      }
    ]

    return {
      currentView: views[0],
      views
    }
  },
  computed: {
    ...mapGetters({
      settings: 'generalSettings',
      analyticsCurrentQuery: 'getAnalyticsCurrentQuery',
      recordsLoading: 'getAnalyticsRecordsLoading'
    }),
    chartsEnabled () {
      return this.query.criteria.scorableFilter
    },
    showTable () {
      return this.currentView.component === 'AnalyticsDataTable'
    },
    showCharts () {
      return this.currentView.component === 'AnalyticsResultCharts'
    }
  },
  watch: {
    query: {
      deep: true,
      handler () {
        const chartView = this.views.find(item => item.value === 'charts')
        chartView.disabled = !this.chartsEnabled
      }
    }
  },
  methods: {
    setCurrentView (view) {
      if (view.disabled === false) {
        this.currentView = view
      }
    },
    refreshView () {
      if (this.$refs.AnalyticsDataTable) {
        this.$refs.AnalyticsDataTable.refreshView()
      }
      // don't make query calls for charts if item-level measures are selected
      if (this.$refs.AnalyticsResultCharts && this.analyticsCurrentQuery.scorableFilter) {
        this.$refs.AnalyticsResultCharts.refreshView()
      }
    },
    buttonDisabled (view) {
      if (view.value === 'charts' && (this.chartsEnabled === false || this.recordsLoading)) {
        view.disabled = true
        return true
      }
      view.disabled = false
      return false
    },
    tabButtonTooltip (view) {
      if (view.value === 'charts' && this.chartsEnabled === false) {
        return { content: `${this.$t('selectScores')}`, placement: 'left', classes: [''] }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.header-actions-group {
  height: 28px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.analytics-table-header-actions {
  margin-left: auto;
}
</style>

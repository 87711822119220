<template>
  <div class="analytics-chip-wrapper">
    <section class="analytics-chip-group service-line-group">
      <div class="analytics-chip-heading-group">
        <h3>{{ sectionName }}</h3>
        <span :class="serviceLineMessageClass">{{ serviceLineMessage }}</span>
      </div>
      <ul>
        <li
          v-for="(item, idx) in serviceLineItems"
          :key="idx"
        >
          <owl-chip
            v-tooltip.bottom="item.label"
            :label="truncateLabel(item.label)"
            :state="getServiceLineChipState(item.value)"
            @on-click="updateServiceLineChipState(item.value)"
          />
        </li>
      </ul>
    </section>
    <section class="analytics-chip-group chart-group right-container">
      <div class="analytics-chip-heading-group">
        <h3
          v-if="totalRecords > 0"
          :class="noServiceLinesSelected"
        >
          Population Charts
        </h3>
        <span :class="chartMessageClass">{{ chartMessage }}</span>
      </div>
      <ul
        class="analytics-chip-measures-list"
        :class="{'chip-overflow': isMaxChipLength}"
      >
        <li
          v-for="(item, idx) in visibleChartItems"
          :key="idx"
        >
          <owl-chip
            ref="chartChip"
            v-tooltip.bottom="item.abbreviation"
            :label="truncateLabel(item.abbreviation)"
            :state="item.state"
            @on-click="toggleChartChipState(item.uuid, item.state)"
          />
        </li>
      </ul>
      <button
        v-if="visibleChartItems.length > 25"
        class="overflow-toggle"
        @click="toggleOpen"
      >
        <i
          class="fas filter-toggle tw-pr-1"
          :class="{'fa-caret-down': !opened, 'fa-caret-up': opened}"
        />{{ chipOverflowLabel }}
      </button>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { OwlChip } from 'owl-components'
import cloneDeep from 'lodash/cloneDeep'
import sortBy from 'lodash/sortBy'
import { setChartData } from '../helpers/ChipFilterHelpers.js'

const DEFAULT_SELECTED_CHARTS = 3
const MAX_SELECTED_SERVICE_LINES = 3
const MAX_SELECTED_CHARTS = 5

export default {
  name: 'AnalyticsChipWrapper',
  components: { OwlChip },
  props: {
    serviceLineItems: {
      type: Array,
      default: () => []
    },
    recordsDataQuery: null
  },
  data () {
    return {
      mutableServiceLineItems: this.serviceLineItems,
      selectedServiceLines: [],
      visibleChartItems: [],
      selectedChartItems: [],
      disabledChartItems: [],
      disabledServiceLineItems: [],
      opened: false,
      visibleChartItemsCatchall: [{
        abbreviation: 'Patient Status',
        uuid: 'catchall-uuid-patient-status',
        state: 'unselected'
      }, {
        abbreviation: 'Demographics',
        uuid: 'catchall-uuid-demographics',
        state: 'unselected'
      }]
    }
  },
  computed: {
    ...mapGetters({
      totalRecords: 'getTotalRecordsForCharts',
      availablePrograms: 'getServiceLinesForChips',
      availableMeasures: 'getMeasuresForChips',
      programsFiltered: 'getAnalyticsProgramsFiltered',
      chartCollectionLoading: 'getAnalyticsChartCollectionLoading',
      selectedServiceLine: 'getSelectedServiceLine'
    }),
    sectionName () {
      return this.selectedServiceLine === 'programFilter' ? 'Programs' : 'Locations'
    },
    sectionNameLabel () {
      return this.selectedServiceLine === 'programFilter' ? 'program' : 'location'
    },
    serviceLineMessage () {
      return this.totalRecords === 0 ? '' : this.selectedServiceLines.length === 0 ? `Select at least one ${this.sectionNameLabel}` : `Select up to ${MAX_SELECTED_SERVICE_LINES}`
    },
    chartMessage () {
      return this.totalRecords === 0 || this.selectedServiceLines.length === 0 ? '' : this.selectedChartItems.length === 0 ? 'Select at least one chart' : `Select up to ${MAX_SELECTED_CHARTS}`
    },
    serviceLineMessageClass () {
      return this.selectedServiceLines.length === 0 ? 'warn' : ''
    },
    chartMessageClass () {
      return this.selectedChartItems.length === 0 ? 'warn' : ''
    },
    noServiceLinesSelected () {
      return this.selectedServiceLines.length === 0 ? 'tw-text-owl-gray-border' : ''
    },
    chipOverflowLabel () {
      return this.opened ? 'Show Less' : 'Show More'
    },
    noChipsSelected () {
      return this.selectedChartItems.length === 0
    },
    isMaxChipLength () {
      return this.visibleChartItems.length > 25 && !this.opened
    }
  },
  watch: {
    mutableServiceLineItems: {
      handler () {
        this.setServiceLineDisabledState()
        this.updateChartData()
      },
      deep: true
    },
    visibleChartItems: {
      handler () {
        this.setDisabledChartState()
        this.updateChartData()
      },
      deep: true
    }
  },
  beforeMount () {
    // if no records, disable service line chips and show messaging
    if (Number(this.totalRecords === 0)) {
      this.disableServiceLineChips()
    } else {
      // set default service line chip states
      this.serviceLineItems.map(item => {
        item.state = 'unselected'
        return item
      })
      // set 1st service line chip and 1st 3 chart chips to selected
      this.serviceLineItems[0].state = 'selected'
      this.selectedServiceLines.push(this.serviceLineItems[0].value)
      this.setVisibleChartItems()

      this.visibleChartItems.forEach((value, key) => {
        if (key < DEFAULT_SELECTED_CHARTS) {
          this.selectedChartItems.push(value.uuid)
          return this.setChartChipState(value.uuid, 'selected')
        }
      })
    }
  },
  methods: {
    disableServiceLineChips () {
      this.serviceLineItems.forEach(item => {
        item.state = 'disabled'
      })
    },
    setVisibleChartItems (serviceLineState = 'selected') {
      let measureArray = []
      this.selectedServiceLines.forEach(item => {
        const measuresToPush = this.setMeasuresForSelectedServiceLine(item, serviceLineState)
        measureArray = measureArray.concat(measuresToPush)
      })
      const uniqueArray = Array.from(new Set(measureArray.map(JSON.stringify)), JSON.parse)

      // clone the visibleChartItemsCatchall and add it to visibleChartItems
      // don't show catchall chips if no service lines are selected
      this.visibleChartItems = sortBy(uniqueArray, 'abbreviation')
      if (this.selectedServiceLines.length > 0) {
        const tempArray = cloneDeep(this.visibleChartItemsCatchall)
        this.visibleChartItems = this.visibleChartItems.concat(tempArray)
      }

      if (serviceLineState === 'unselected') {
        const cleanList = this.selectedChartItems.filter(item => this.visibleChartItems.find(i => i.uuid === item))
        this.selectedChartItems = cleanList
      }
      this.updateCatchallChipState()
    },
    getServiceLineChipState (uuid) {
      const el = this.mutableServiceLineItems.find(item => item.value === uuid)
      return el ? el.state : 'unselected'
    },
    setServiceLineChipState (uuid, state) {
      const match = this.mutableServiceLineItems.find(item => item.value === uuid)
      if (match) {
        this.mutableServiceLineItems.find(item => item.value === uuid).state = state
      }
    },
    updateServiceLineChipState (uuid) {
      const currentState = this.getServiceLineChipState(uuid)
      const newState = currentState === 'unselected' ? 'selected' : 'unselected'
      const updatedState = this.mutableServiceLineItems.map(item => {
        if (item.value === uuid) {
          return { ...item, state: newState }
        }
        return item
      })
      this.mutableServiceLineItems = updatedState
      const slFilter = updatedState.filter(item => item.state === 'selected')
      const slArray = slFilter.map(item => item.value)
      this.selectedServiceLines = slArray

      this.setVisibleChartItems(newState)
    },
    updateChartData () {
      const chartData = setChartData(this.programsFiltered, this.selectedServiceLines, this.selectedChartItems)
      this.$emit('updateChartFilter', { chartData: chartData, selectedChartChipCount: Object.keys(this.selectedChartItems).length })
    },
    setChartChipState (uuid, state) {
      const match = this.visibleChartItems.find(item => item.uuid === uuid)
      if (match) {
        this.visibleChartItems.find(item => item.uuid === uuid).state = state
      }
    },
    toggleChartChipState (uuid, currentState) {
      this.visibleChartItems.find(item => item.uuid === uuid).state = currentState === 'unselected' ? 'selected' : 'unselected'
      this.updateChartChipState(uuid, currentState)
    },
    updateChartChipState (uuid, currentState) {
      if (currentState === 'unselected') {
        // if pre-click state is 'unselected', set the chip state to selected by pushing to selected list(s)
        this.selectedChartItems.push(uuid)
      } else {
        // if pre-click state was 'selected' or 'disabled', set the chip state to unselected by removing it from selected list(s)
        const index = this.selectedChartItems.indexOf(uuid)
        if (index !== -1) {
          this.selectedChartItems.splice(index, 1)
        }
      }
    },
    setServiceLineDisabledState () {
      if (this.selectedServiceLines.length === 0) {
        this.opened = false
      }
      const unselected = this.mutableServiceLineItems.filter(item => item.state !== 'selected')
      if (this.selectedServiceLines.length >= MAX_SELECTED_SERVICE_LINES) {
        this.disabledServiceLineItems = []
        unselected.forEach(item => {
          this.disabledServiceLineItems.push(item.value)
          return this.setServiceLineChipState(item.value, 'disabled')
        })
      } else {
        this.disabledServiceLineItems.forEach(item => {
          return this.setServiceLineChipState(item, 'unselected')
        })
        this.disabledServiceLineItems = []
      }
    },
    setDisabledChartState () {
      const unselected = this.visibleChartItems.filter(item => !this.selectedChartItems.includes(item.uuid))
      if (this.selectedChartItems.length >= MAX_SELECTED_CHARTS) {
        this.disabledChartItems = []
        unselected.forEach(item => {
          this.disabledChartItems.push(item.uuid)
          return this.setChartChipState(item.uuid, 'disabled')
        })
      } else {
        this.disabledChartItems.forEach(item => {
          return this.setChartChipState(item, 'unselected')
        })
        this.disabledChartItems = []
      }
    },
    setMeasuresForSelectedServiceLine (uuid, serviceLineState) {
      const commonMeasures = this.getCommonMeasures(uuid)
      const keyValuePairs = commonMeasures.map(v => [v.uuid, v.abbreviation]) // We transform to a k/v pair
      const uniq = Object.fromEntries(keyValuePairs) // then back to an object, removing dups
      const state = this.selectedChartItems.length >= MAX_SELECTED_CHARTS ? 'disabled' : 'unselected'
      const simplifiedMeasures = Object.entries(uniq).map(([uuid, abbreviation]) => ({ uuid, abbreviation, state: state }))

      simplifiedMeasures.forEach(item => {
        item.state = this.selectedChartItems.includes(item.uuid) ? 'selected' : 'unselected'
      })

      return simplifiedMeasures
    },
    updateCatchallChipState () {
      if (this.selectedChartItems.includes('catchall-uuid-patient-status')) {
        this.visibleChartItems.find(item => item.uuid === 'catchall-uuid-patient-status').state = 'selected'
      }
      if (this.selectedChartItems.includes('catchall-uuid-demographics')) {
        this.visibleChartItems.find(item => item.uuid === 'catchall-uuid-demographics').state = 'selected'
      }
    },
    getOutcomesMeasures (uuid) {
      // here we'll add to the chart collection - later we want to move this into store
      const commonMeasures = this.getCommonMeasures(uuid)
      return commonMeasures.map(m => { return { measure_uuid: m.uuid, abbreviation: m.abbreviation, location_uuid: uuid } })
    },
    getCommonMeasures (uuid) {
      // get all available measures from available service lines
      const unfilteredMeasures = this.availablePrograms.find(item => item.uuid === uuid).measures
      // filter out measures without charts
      const measuresWithCharts = this.availableMeasures.filter(item => item.has_graph === 1)
      // return the common UUIDs as a simplified array
      return unfilteredMeasures.filter(item => measuresWithCharts.find(el => el.uuid === item.uuid))
    },
    truncateLabel (value) {
      // limit label to 20 chars, show ellipsis if longer
      return value.length > 20 ? `${value.substring(0, 20)}...` : value
    },
    toggleOpen () {
      this.opened ? this.opened = !this.opened : this.opened = true
    }
  }
}
</script>

<style lang="scss" scoped>
  .analytics-chip-wrapper {
    @apply tw-mt-6 tw-mb-6 tw-pt-5 tw-border-t-2 tw-border-owl-gray-canvas tw-flex; // tw-justify-between
    section {
      @apply tw-w-5/12;
      &.right-container {
        @apply tw-pl-24 tw-w-7/12;
      }
      .analytics-chip-heading-group {
        @apply tw-flex;
        h3 {
          @apply tw-text-base tw-mb-0 tw-justify-start tw-w-4/12;
        }
        span {
          @apply tw-font-light tw-text-right tw-justify-end tw-w-7/12;
          padding-top: 3px;
          &.warn {
            @apply tw-font-medium tw-text-owl-alert-orange-strong;
          }
        }
      }
      ul {
        @apply tw-mb-0 tw-clear-both tw-overflow-hidden;
      }
      .chip-overflow {
        @apply tw-overflow-hidden tw-h-20
      }
      li {
        @apply tw-float-left;
      }
      button {
        @apply tw-pt-4;
        outline: none;
        &.overflow-toggle {
          @apply tw-flex tw-text-right tw-justify-end tw-w-11/12;
          border: none;
          box-shadow: none;
        }
      }
    }
  }
</style>

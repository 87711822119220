<template>
  <div
    v-if="Object.keys(data).length"
    slot="content"
  >
    <div
      v-for="(d, index) in data"
      :key="`outer_${index}`"
    >
      <h4>{{ d.measureAbbr }} {{ sectionTitle }}</h4>
      <div
        v-for="(chart, i) in d.admitAndDischargeScores"
        :key="`ads_${i}`"
      >
        <AnalyticsResultChartsOutcomesAdmissionVsDischarge :data="chart" />
      </div>
      <div
        v-for="(chart, i) in d.scoreOverTime"
        :key="`sot_${i}`"
      >
        <AnalyticsResultChartsOutcomesScoresOverTime :data="chart" />
      </div>
    </div>
  </div>
</template>

<script>
import AnalyticsResultChartsOutcomesAdmissionVsDischarge from '@/components/analytics/outcomesExplorer/charts/outcomes/AnalyticsResultChartsOutcomesAdmissionVsDischarge'
import AnalyticsResultChartsOutcomesScoresOverTime from '@/components/analytics/outcomesExplorer/charts/outcomes/AnalyticsResultChartsOutcomesScoresOverTime'
export default {
  name: 'AnalyticsResultChartsOutcomes',
  components: {
    AnalyticsResultChartsOutcomesScoresOverTime,
    AnalyticsResultChartsOutcomesAdmissionVsDischarge
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    sectionTitle () {
      return this.$t('programOutcomesTitle')
    }
  }
}
</script>

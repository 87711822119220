export const ChartChangesHelper = {
  methods: {
    changeHighChartsTitle (newTitle) {
      this.$refs.chartRef.updateTitle(newTitle)
    },
    changeHighChartsCaption (newCaption) {
      this.$refs.chartRef.updateCaption(newCaption)
    },
    adjustMenuIcon (el) {
      this.$refs.chartWrapper.adjustMenuIcon(el)
    }
  }
}

<template>
  <div>
    <!-- TODO clean this up -->
    <div
      v-if="!programsLoading && noRecords"
      class="text-center pt-4"
    >
      {{ noRecordsMessage }}
    </div>
    <div v-else-if="!programsLoading">
      <virtual-list
        class="container-fluid"
        style="height: calc(100vh - 290px; overflow-y: auto;"
        :data-key="'dataKey'"
        :data-sources="items"
        :data-component="itemComponent"
        :estimate-size="70"
        @tobottom="onScrollToBottom"
      />
    </div>
  </div>
</template>
<script>

import { mapGetters } from 'vuex'
import VirtualList from 'vue-virtual-scroll-list'
import AnalyticsChartItem from '@/components/analytics/outcomesExplorer/charts/AnalyticsChartItem'
import Helpers from '@/mixins/Helpers'
const ITEMS_LIMIT_PER_FETCH = 1

export default {
  name: 'AnalyticsChartVirtualScroll',
  components: { 'virtual-list': VirtualList },
  mixins: [Helpers],
  // TODO: define props
  props: ['chartData', 'isVisible', 'noRecordsMessage'], // eslint-disable-line vue/require-prop-types
  data () {
    return {
      interval: null,
      VirtualListKey: 0,
      itemComponent: AnalyticsChartItem,
      items: [],
      programSelection: null,
      noRecords: false
    }
  },
  computed: {
    ...mapGetters({
      programsLoading: 'getAnalyticsProgramsFilteredLoading'
    })
  },
  watch: {
    chartData: {
      deep: true,
      handler (newVal) {
        this.setItems()
      }
    }
  },
  created () {
    this.setItems()
  },
  methods: {
    refreshView () {
      this.items = []
      this.getItems()
    },
    refreshSelected (chartData) {
      this.programSelection = chartData
      this.refreshView()
    },
    setItems () {
      this.items = this.chartData
      this.noRecords = this.items.length === 0
    },
    getItems () {
      this.items = this.getCollection()
      return this.items
    },
    getCollection () {
      return this.items.concat(this.chartData.slice(this.items.length, (this.items.length + ITEMS_LIMIT_PER_FETCH)))
    },
    onScrollToBottom () {
      if (this.items.length < this.chartData.length) {
        this.getItems()
      }
    }
  }
}
</script>

<template>
  <div v-if="items && items.length">
    <span v-if="items.length === 1">
      {{ items[0] }}
    </span>
    <div
      v-else
      class="items-clinicians"
    >
      <v-popover
        :container="false"
        popover-class="condensed-list-tooltip"
        :placement="'left'"
      >
        {{ items.length }} {{ itemsText }}
        <template slot="popover">
          <div
            v-for="(item, i) in items"
            :key="i"
          >
            {{ item }}
          </div>
        </template>
      </v-popover>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CondensedListOfItems',
  props: ['items', 'text'], // eslint-disable-line vue/require-prop-types
  computed: {
    itemsText () {
      return this.text || this.$t('items')
    }
  },
  methods: {
    getItems () {
      if (this.items && this.items.length) {
        return this.items.reduce((text, i) => `${text} <div>${i}</div>`, '')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.items-clinicians {
  text-transform: capitalize;
  white-space: nowrap;
}
</style>

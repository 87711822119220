<template>
  <i
    v-tooltip="tooltipData"
    class="pointer"
    :class="classObj"
    @click="toggle"
    @mouseleave="closeFunction"
  />
</template>

<script>
export default {
  name: 'IconButton',
  props: {
    status: {
      type: Boolean,
      default: false
    },
    closeOnMouseOutside: {
      type: Boolean,
      default: false
    },
    isError: {
      type: Boolean,
      default: false
    },
    isWarning: {
      type: Boolean,
      default: false
    },
    isSuccess: {
      type: Boolean,
      default: false
    },
    tooltipData: {
      type: Object,
      default: null
    }
  },
  computed: {
    classObj () {
      const obj = { fas: this.status, fal: !this.status }
      if (this.isError) {
        obj['color-error'] = true
      } else if (this.isWarning) {
        obj['color-warning'] = true
      } else if (this.isSuccess) {
        obj['color-success'] = true
      }
      return obj
    }
  },
  methods: {
    closeFunction () {
      if (this.closeOnMouseOutside) {
        this.$emit('close')
      }
    },
    toggle () {
      this.$emit('changed', this.status)
    }
  }
}
</script>
<style lang="scss" scoped>
  .pointer:hover {
    cursor: pointer;
  }
</style>

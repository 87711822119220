<template>
  <div
    ref="analyticsSavedQueriesRef"
    class="analytics-load-saved-queries"
  >
    <button
      :class="{ 'menu-open' : opened }"
      class="btn btn-outline-secondary"
      @click="toggleMenu"
    >
      {{ $t('savedQueries') }} <i
        :class="{ 'menu-open fa-caret-up' : opened }"
        class="fas fa-caret-down"
      />
    </button>
    <div
      v-if="opened"
      class="analytics-load-saved-queries-menu-wrapper"
    >
      <div class="analytics-load-saved-queries-menu p-3">
        <div v-if="savedQueries && savedQueries.length">
          <div class="row mb-4">
            <div class="col-11 col-lg-4 col-xl-3">
              <search-input
                ref="queryFilter"
                v-model="filter"
                :placeholder="`${ $t('searchQueries') }...`"
                focus
              />
            </div>
            <div class="col-1 col-lg-8 col-xl-9 text-right">
              <button
                class="btn btn-bare"
                @click="closeMenu"
              >
                <i class="fal fa-times lead" />
              </button>
            </div>
          </div>

          <div
            v-if="filteredSavedQueries && filteredSavedQueries.length"
            class="row"
          >
            <div
              v-for="savedQuery in filteredSavedQueries"
              :key="savedQuery.id"
              class="col-6 col-lg-4 col-xl-3 pb-3"
            >
              <div
                class="analytics-load-saved-query px-3 btn btn-outline-gray"
                @click.self="loadQuery(savedQuery)"
              >
                <button
                  class="btn btn-bare mr-2"
                  @click="setFavoriteQuery(savedQuery)"
                >
                  <i
                    :class="savedQuery.favorite ? 'fas' : 'fal'"
                    class="fa-star"
                  />
                </button>
                <button
                  class="analytics-load-saved-query-name btn btn-bare"
                  @click="loadQuery(savedQuery)"
                >
                  {{ savedQuery.name }}
                </button>
                <button
                  class="show-on-hover btn btn-bare"
                  @click="openEditSavedQueryModal(savedQuery)"
                >
                  <i class="fal fa-fw fa-edit" />
                </button>
                <button
                  class="show-on-hover btn btn-bare mx-1"
                  @click="openDeleteSavedQueryModal(savedQuery)"
                >
                  <i class="fal fa-fw fa-trash-alt" />
                </button>
              </div>
            </div>
          </div>
          <div v-else>
            <p>{{ $t('noResultsFound') }}</p>
          </div>
        </div>
        <div v-else>
          <p>{{ $t('analyticsSavedQueries.analyticsLoadSavedQueryNoEntries') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import SearchInput from '@/components/common/SearchInput'
import { Helpers } from '@/mixins/Helpers'
import { ModalMixin } from '@/mixins/modal/ModalMixin'
import { mapGetters, mapMutations } from 'vuex'
import { ANALYTICS_GET_SAVED_QUERIES, ANALYTICS_SET_SELECTED_QUERY_FOR_EDITION, ANALYTICS_UPDATE_SAVED_QUERY, ANALYTICS_LOAD_SAVED_QUERY } from '@/store/modules/analytics/outcomes-explorer/constants'
import { MUTATION_SET_MODAL_CONFIG, MUTATION_SET_MODAL_OPENED, MUTATION_SET_MODAL_TEMPLATE } from '@/store/modules/applicationState/constants'

export default {
  name: 'AnalyticsLoadSavedQueries',
  components: { SearchInput },
  mixins: [Helpers, ModalMixin],
  data () {
    return {
      opened: false,
      addedEventListener: false,
      filter: ''
    }
  },
  computed: {
    ...mapGetters({
      analyticsRecordsLoading: 'getAnalyticsRecordsLoading',
      savedQueries: 'getAnalyticsSavedQueries',
      modalIsOpen: 'getCustomModalIsOpened'
    }),
    isLoading () {
      return this.analyticsRecordsLoading
    },
    filteredSavedQueries () {
      return !this.filter.length ? this.savedQueries : this.savedQueries.filter((query) => query.name.toLowerCase().indexOf(this.filter.toLowerCase()) > -1)
    }
  },
  created () {
    this.$genericDispatch(ANALYTICS_GET_SAVED_QUERIES)
  },
  beforeDestroy () {
    this.closeMenu()
  },
  methods: {
    ...mapMutations({
      setModalOpen: MUTATION_SET_MODAL_OPENED,
      setCustomModalConfig: MUTATION_SET_MODAL_CONFIG,
      setModalTemplate: MUTATION_SET_MODAL_TEMPLATE,
      setSelectedQueryForEdition: ANALYTICS_SET_SELECTED_QUERY_FOR_EDITION,
      loadSavedQuery: ANALYTICS_LOAD_SAVED_QUERY
    }),
    setFavoriteQuery (savedQuery) {
      return this.$genericDispatch(ANALYTICS_UPDATE_SAVED_QUERY, { ...savedQuery, favorite: !savedQuery.favorite })
    },
    loadQuery (savedQuery) {
      this.$emit('loadQuery', savedQuery)
      this.closeMenu()
    },
    openDeleteSavedQueryModal (savedQuery) {
      this.openModal('AnalyticsDeleteSavedQueryModal', savedQuery, {
        customClass: 'analytics-delete-query-modal'
      })
    },
    openEditSavedQueryModal (savedQuery) {
      this.openModal('AnalyticsQueryFormModal', savedQuery, {
        action: 'update',
        customClass: 'analytics-save-query-modal'
      })
    },
    toggleMenu () {
      if (this.opened) {
        this.closeMenu()
      } else {
        this.openMenu()
      }
    },
    openMenu () {
      document.addEventListener('click', this.handleClickOutside)
      this.opened = true
    },
    closeMenu () {
      document.removeEventListener('click', this.handleClickOutside)
      this.opened = false
    },
    openModal (templateName, query, config) {
      this.setSelectedQueryForEdition(query)
      this.setModalTemplate(templateName)
      this.setCustomModalConfig(config)
      this.setModalOpen(true)
    },
    handleClickOutside (event) {
      if (this.modalIsOpen) {
        return
      }

      const el = this.$refs.analyticsSavedQueriesRef
      const target = event.target

      if (el && el !== target && !el.contains(target)) {
        this.closeMenu()
      }
    }
  }
}
</script>

<template>
  <div class="measure-filter-search">
    <search-input
      v-model="search"
      focus
      class="input-group-sm"
      placeholder=""
      @input="$emit('search', search)"
    />
    <div :ref="refName">
      <FilterContent
        v-model="measuresConditionFilter"
        custom-label-key="condition_text"
        custom-value-key="id"
        :custom-submit="$t('apply')"
        :opened="opened"
        :condensed="true"
        :hide-close-icon="true"
        :selected-label="selectedLabel"
        :items="measuresConditions"
        :selected="measuresConditionFilter"
        @toggleOpened="opened = !opened"
        @close="opened = false"
        @applyFilter="applyFilter"
      >
        <template
          slot="header"
          slot-scope="{ selectAll, selectNone }"
        >
          {{ selectedLabel }}
          <div>
            <button
              class="btn btn-bare"
              @click="selectAll"
            >
              {{ $t('all') }}
            </button>
            <span>|</span>
            <button
              class="btn btn-bare"
              @click="selectNone"
            >
              {{ $t('none') }}
            </button>
          </div>
        </template>
      </FilterContent>
    </div>
  </div>
</template>

<script>
import SearchInput from '@/components/common/SearchInput'
import FilterContent from '@/components/admin/settings/common/FilterContent'
import { HandleClickOutside } from '@/mixins/HandleClickOutside'

const defaultSearchValue = ''

export default {
  name: 'AnalyticsMeasuresFilterHeaderSearch',
  components: {
    FilterContent,
    SearchInput
  },
  mixins: [HandleClickOutside],
  props: {
    measuresConditions: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      opened: false,
      search: defaultSearchValue,
      measuresConditionFilter: this.measuresConditions.map(mc => mc.id)
    }
  },
  computed: {
    selectedLabel () {
      if (!this.measuresConditionFilter.length) {
        return this.$t('noConditions')
      } else if (this.measuresConditionFilter.length === 1) {
        const condition = this.measuresConditions.find(mc => mc.id === this.measuresConditionFilter[0])
        return condition ? condition.condition_text : this.$t('someConditions')
      } else if (this.measuresConditions.length === this.measuresConditionFilter.length) {
        return this.$t('allConditions')
      } else {
        return this.$t('someConditions')
      }
    }
  },
  methods: {
    closeFunction () {
      this.opened = false
    },
    applyFilter () {
      this.opened = false
      this.$emit('filterByConditions', this.measuresConditionFilter)
    },
    resetFilters () {
      this.search = defaultSearchValue
      this.measuresConditionFilter = this.measuresConditions.map(mc => mc.id)
    }
  }
}
</script>

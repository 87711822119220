<template>
  <label class="radio-label">
    <input
      v-show="false"
      v-model="radioButtonValue"
      type="radio"
      :value="val"
      :name="name"
    >
    <i
      :class="{'far fa-dot-circle': radioButtonValue === val, 'fal fa-circle': radioButtonValue !== val}"
      @click="selectionChange(val)"
    />
    <slot name="customLabel">
      <span v-html="label" />
    </slot>
  </label>
</template>
<script>

export default {
  name: 'CustomRadio',
  props: ['name', 'val', 'value', 'label'], // eslint-disable-line vue/require-prop-types
  data () {
    return {
      originalValue: null,
      editMode: !this.label,
      newValue: null
    }
  },
  computed: {
    radioButtonValue: {
      get: function () {
        return this.value
      },
      set: function () {
        this.$emit('input', this.val)
      }
    }
  },
  created () {
    this.originalValue = this.label
    this.newValue = this.label
  },
  methods: {
    setAsSelectedOption () {
      this.radioButtonValue = this.val
    },
    enableEditMode () {
      this.editMode = true
    },
    disableEditMode () {
      this.revertValueChanges()
      this.editMode = false
    },
    revertValueChanges () {
      this.newValue = this.originalValue
      this.$emit('changedChannelValue', null)
    },
    setUpdatedValue () {
      this.$emit('changedChannelValue', this.newValue)
    },
    selectionChange () {
      this.radioButtonValue = this.val
      if (this.label) {
        this.disableEditMode()
      }
      this.$emit('input', this.val)
    }
  }
}
</script>
<style lang="scss" scoped>
label {
  span:hover,
  i:hover {
    cursor: pointer;
  }
}
</style>

<template>
  <chart
    ref="highchartChart"
    class="pie-chart analytics-chart"
    :options="chartOptions"
    :callback="chartCallback"
  />
</template>

<script>
import { Chart } from 'highcharts-vue'
import { ChartsHelper } from '@/mixins/ChartsHelper'

export default {
  name: 'PieChart',
  components: {
    Chart
  },
  mixins: [ChartsHelper],
  props: {
    chart: {
      type: Object,
      default: () => {}
    }
  },
  data: function () {
    return {
      chartOptions: this.getDefaultChartConfig('pie', this.chart)
    }
  },
  methods: {
    chartCallback () {
      this.$emit('adjustMenuIcon', this.$refs.highchartChart.$el)
    }
  }
}
</script>
<style lang="scss"></style>

<template>
  <AnalyticsFilter
    :filters="computedFilters"
    :custom-button-text="customButtonText"
    :before-execute="beforeExecute"
    :error="$t('atLeast1ClinicianOrClinicalTeam')"
    :show-error="showError"
    @executeFilter="executeFilterAction(false)"
    @resetModel="cancelChanges"
  />
</template>

<script>
import AnalyticsFilter from '@/components/analytics/filters/AnalyticsFilter'
import { mapGetters } from 'vuex'
import { AnalyticsFilterHelpers } from '@/mixins/analytics/AnalyticsFilterHelpers'

export default {
  name: 'AnalyticsClinicianAndTeam',
  components: { AnalyticsFilter },
  mixins: [AnalyticsFilterHelpers],
  data () {
    return {
      selectedClinicians: [],
      selectedClinicianTeams: [],
      clinicianFilter: {
        title: this.$t('clinicians'),
        type: 'checkbox',
        filterName: 'clinicians',
        filterKey: 'clinicianFilter',
        selectedKey: 'selectedClinicians',
        availableKey: 'analyticClinicians',
        label: 'full_name',
        initAllSelected: true,
        required: true,
        model: [],
        items: []
      },
      clinicianTeamFilter: {
        title: this.$t('clinicalTeams'),
        type: 'checkbox',
        filterName: 'clinical_teams',
        filterKey: 'clinicianTeamFilter',
        selectedKey: 'selectedClinicianTeams',
        availableKey: 'analyticClinicalTeams',
        label: 'name',
        initAllSelected: true,
        required: true,
        model: [],
        items: [],
        hidden: this.hideClinicianTeams
      }
    }
  },
  computed: {
    ...mapGetters({
      analyticClinicians: 'getAnalyticClinicians',
      analyticClinicalTeams: 'getAnalyticClinicalTeams',
      analyticsCurrentQuery: 'getAnalyticsCurrentQuery'
    }),
    programsEnabled () {
      return this.$store.getters.generalSettings.programs_enabled
    },
    hideClinicianTeams () {
      return !this.programsEnabled || this.analyticClinicalTeams.length === 0 || (this.analyticsCurrentQuery && this.analyticsCurrentQuery.program_or_location_filter === 'locationFilter')
    },
    allCliniciansSelected () {
      return this.computedFilters.every(filter => this[filter.selectedKey].length === this[filter.availableKey].length)
    },
    customButtonText () {
      return this.allCliniciansSelected
        ? this.$t('allClinicians')
        : this.selectedClinicianTeams.length || this.selectedClinicians.length > 1
          ? this.$t('someClinicians')
          : this.selectedClinicians.length === 1 && this.selectedClinicianTeams.length === 0
            ? () => {
                const match = this.clinicianFilter.items.filter(item => item.value === this.selectedClinicians[0])
                return match.length ? match[0].label : false
              }
            : this.$t('noClinicians')
    },
    computedFilters () {
      return this.hideClinicianTeams ? [this.clinicianFilter] : [this.clinicianTeamFilter, this.clinicianFilter]
    }
  },
  watch: {
    analyticClinicians () {
      this.setClinicianFilterItems()
    },
    analyticClinicalTeams () {
      this.setClinicianTeamFilterItems()
    }
  },
  created () {
    this.setClinicianFilterItems()
    this.setClinicianTeamFilterItems()
    this.clinicianTeamFilter.hidden = this.hideClinicianTeams
  },
  updated () {
    this.clinicianTeamFilter.hidden = this.hideClinicianTeams
  },
  methods: {
    updateValuesFromQuery (savedQuery) {
      const data = Object.assign({}, savedQuery)
      const hasClinicians = data.query.hasOwnProperty('clinicians') // eslint-disable-line no-prototype-builtins
      const hasTeams = data.query.hasOwnProperty('clinical_teams') && data.query.clinical_teams.length > 0 // eslint-disable-line no-prototype-builtins
      // since the computedFilters property can have different sizes based on hiding options, need to check that here
      if (this.computedFilters.length > 1) {
        this.computedFilters[0].model = hasTeams ? data.query.clinical_teams : []
        this.computedFilters[1].model = hasClinicians ? data.query.clinicians : []
      } else {
        this.computedFilters[0].model = hasClinicians ? data.query.clinicians : []
      }

      this.executeFilterAction(true)
    },
    setClinicianFilterItems () {
      this.setAnalyticsFilterItems(this.clinicianFilter)
    },
    setClinicianTeamFilterItems () {
      if (!this.hideClinicianTeams) {
        this.setAnalyticsFilterItems(this.clinicianTeamFilter)
      } else {
        this.clearAnalyticsFilterItems('clinical_teams')
      }
    },
    cancelChanges () {
      if (!this.hideClinicianTeams) {
        this.cancelModelChange(this.clinicianTeamFilter, this.analyticsCurrentQuery.clinical_teams)
      }
      this.cancelModelChange(this.clinicianFilter, this.analyticsCurrentQuery.clinicians)
    }
  }
}
</script>

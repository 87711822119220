<template>
  <AnalyticsFilter
    :filters="computedFilters"
    :custom-button-text="customButtonText"
    :before-execute="beforeExecute"
    :error="errorMessage"
    class="measure-filter"
    :show-error="showError"
    @executeFilter="updateScorableState"
    @resetModel="cancelChanges"
  >
    <template
      slot="title"
      slot-scope="{ filter, selectAll, selectNone, items }"
    >
      <div class="filter-title measure-filter-header">
        <div class="scorable-selector">
          <span>{{ $t('reportOn') }}:</span>
          <div class="radio-container">
            <CustomRadio
              id="scorable_option"
              v-model="scorableFilter"
              name="scorableFilter"
              :label="$t('measureScoreFilter.scorable')"
              :val="true"
              @input="resetFilterModel"
            />
            <CustomRadio
              id="item_level_option"
              v-model="scorableFilter"
              name="scorableFilter"
              :label="$t('measureScoreFilter.itemLevel')"
              :val="false"
              @input="resetFilterModel(true)"
            />
          </div>
          <CustomDropdown
            v-show="showResultsToInclude"
            v-model="selectedResultsToInclude"
            :items="resultsToIncludeFilter"
            class="results-to-include"
          />
        </div>
        <div class="measure-filter-feedback-wrapper">
          <div>
            <i class="fa fa-check color-success" />
            <div
              v-tooltip="tooltipData"
              class="measure-filter-feedback"
              :class="{'dotted-underline': tooltipData.content}"
              @mouseenter="tooltipData.show = !tooltipData.show"
              @mouseleave="tooltipData.show = false"
            >
              <strong>{{ filter.model.length }}</strong> {{ selectedText }} <span v-if="!scorableFilter">({{ $t('selectOnlyOne') }})</span>
            </div>
            <div
              v-show="scorableFilter"
              class="measure-filter-controls"
            >
              <button
                type="button"
                class="btn btn-bare"
                @click="selectAll(filter, filteredContentItems(items))"
              >
                {{ $t('all') }}
              </button>
              <span>|</span>
              <button
                ref="selectNoneButton"
                type="button"
                class="btn btn-bare"
                @click="selectNone(filter)"
              >
                {{ $t('none') }}
              </button>
            </div>
          </div>
          <AnalyticsMeasuresFilterHeaderSearch
            ref="analyticsMeasuresFilterHeaderSearch"
            :measures-conditions="measuresConditions"
            @search="setSearchFilter"
            @filterByConditions="filterByConditions"
          />
        </div>
      </div>
    </template>
    <template
      slot="filter-content"
      slot-scope="{ items }"
    >
      <div
        v-for="(item, i) in filteredContentItems(items)"
        :key="i"
        class="filter-content-item"
      >
        <AnalyticsMeasuresFilterItem
          :item="item"
          :filter="selectedFilter"
          :index="i"
        />
      </div>
    </template>
  </AnalyticsFilter>
</template>

<script>
import AnalyticsFilter from '@/components/analytics/filters/AnalyticsFilter'
import { mapGetters } from 'vuex'
import { AnalyticsFilterHelpers } from '@/mixins/analytics/AnalyticsFilterHelpers'
import CustomRadio from '@/components/common/CustomRadio'
import CustomDropdown from '@/components/common/navigation/customDropdown'
import AnalyticsMeasuresFilterItem from '@/components/analytics/filters/AnalyticsMeasuresFilterItem'
import AnalyticsMeasuresFilterHeaderSearch from '@/components/analytics/filters/AnalyticsMeasuresFilterHeaderSearch'

export default {
  name: 'AnalyticsMeasuresFilter',
  components: {
    AnalyticsFilter,
    AnalyticsMeasuresFilterItem,
    AnalyticsMeasuresFilterHeaderSearch,
    CustomDropdown,
    CustomRadio
  },
  mixins: [AnalyticsFilterHelpers],
  data () {
    return {
      searchFilter: '',
      measuresConditionFilter: null,
      oldSelection: [],
      selectedScorableMeasuresFilter: [],
      selectedNoScorableMeasuresFilter: [],
      selectedResultsToInclude: '',
      scorableFilter: true,
      currentActiveQueryScorableFilter: true,
      scorableMeasuresFilter: {
        title: this.$t('measures'),
        type: 'checkbox',
        filterName: 'measure',
        filterKey: 'scorableMeasuresFilter',
        selectedKey: 'selectedScorableMeasuresFilter',
        availableKey: 'filteredScorableAnalyticMeasures',
        label: 'abbreviation',
        hideCheckbox: true,
        initAllSelected: false,
        required: true,
        model: [],
        items: []
      },
      noScorableMeasuresFilter: {
        title: this.$t('measures'),
        type: 'checkbox',
        filterName: 'measure',
        filterKey: 'noScorableMeasuresFilter',
        selectedKey: 'selectedNoScorableMeasuresFilter',
        availableKey: 'filteredNoScorableAnalyticMeasures',
        label: 'abbreviation',
        initAllSelected: false,
        hideCheckbox: true,
        limit: 1,
        required: true,
        model: [],
        items: []
      },
      resultsToIncludeFilter: [{
        label: this.$t('resultsToIncludeFilter.allResults'),
        value: 'all_results',
        initSelected: true
      }, {
        label: this.$t('resultsToIncludeFilter.admissionAndDischargeProgram'),
        value: {
          filter: 'admit_discharge',
          column: 'program_assignment_id'
        }
      }, {
        label: this.$t('resultsToIncludeFilter.admissionAndDischargeEpisodeId'),
        value: {
          filter: 'admit_discharge',
          column: 'episode_id'
        }
      }]
    }
  },
  computed: {
    ...mapGetters({
      scorableAnalyticMeasures: 'getScorableAnalyticMeasures',
      noScorableAnalyticMeasures: 'getNoScorableAnalyticMeasures',
      analyticsCurrentQuery: 'getAnalyticsCurrentQuery'
    }),
    showResultsToInclude () {
      return this.analyticsCurrentQuery.program_or_location_filter === 'programFilter'
    },
    selectedFilter () {
      return this.computedFilters[0]
    },
    customButtonText () {
      const selected = this.currentActiveQueryScorableFilter ? this.selectedScorableMeasuresFilter : this.selectedNoScorableMeasuresFilter
      const items = this.currentActiveQueryScorableFilter ? this.scorableAnalyticMeasures : this.noScorableAnalyticMeasures
      return items.length === selected.length
        ? this.$t('allMeasures')
        : selected.length > 1
          ? this.$t('someMeasures')
          : selected.length === 1
            ? () => {
                const match = this.selectedFilter.items.filter(item => item.value === selected[0])
                return match.length ? match[0].label : this.$t('noMeasures')
              }
            : this.$t('noMeasures')
    },
    selectedText () {
      return this.scorableFilter ? this.$t('scoredMeasuresSelected') : this.$t('selected')
    },
    filteredScorableAnalyticMeasures () {
      return this.scorableAnalyticMeasures
    },
    filteredNoScorableAnalyticMeasures () {
      return this.noScorableAnalyticMeasures
    },
    measuresConditions () {
      const measures = this.scorableFilter ? this.scorableAnalyticMeasures : this.noScorableAnalyticMeasures
      return measures.reduce((carry, m) => [...carry, ...m.measure_condition], []).filter((elem, pos, arr) => arr.findIndex(a => a.id === elem.id) === pos)
    },
    tooltipData () {
      if (this.scorableFilter) {
        const content = this.selectedFilter.items.reduce((carry, measure) => this.selectedFilter.model.includes(measure.value) ? `${carry} ${measure.label} <br>` : carry, '')
        return {
          content,
          placement: 'bottom',
          classes: ['supervisor-tooltip']
        }
      }
      return {}
    },
    computedFilters () {
      return this.scorableFilter ? [this.scorableMeasuresFilter] : [this.noScorableMeasuresFilter]
    },
    errorMessage () {
      return this.scorableFilter ? this.$t('atLeast1Measure') : this.$t('only1Measure')
    }
  },
  created () {
    this.setMeasureFilterItems()
    this.setResultsToIncludeFilterItems()
  },
  methods: {
    resetFilterModel (clearSelection) {
      this.resetMeasuresFilterHeaderFilters()
      const filter = this.computedFilters[0]
      this.oldSelection = this.scorableFilter ? this.selectedScorableMeasuresFilter : this.selectedNoScorableMeasuresFilter
      filter.model = [...this.oldSelection]
      this.updateCurrentFilter({ field: filter.filterName, value: filter.model })
      this.setMeasureFilterItems()

      // because of scoped slot, need to simulate click
      if (clearSelection) {
        this.$nextTick().then(() => {
          this.$refs.selectNoneButton.click()
        })
      }
    },
    filteredContentModel (items, model) {
      const filteredItems = this.filteredContentItems(items)

      return filteredItems.filter(item => model.includes(item.value))
    },
    filteredContentItems (items = []) {
      const filteredContentItems = this.measuresConditionFilter && this.measuresConditionFilter.length > 0
        ? items.filter(item => {
          return item.rawData.measure_condition.length > 0
            ? item.rawData.measure_condition.filter(condition => this.measuresConditionFilter.includes(condition.id)).length > 0
            : false
        })
        : items

      return filteredContentItems.filter(item =>
        item.label.toLowerCase().includes(this.searchFilter.toLowerCase()) ||
        item.rawData.name.toLowerCase().includes(this.searchFilter.toLowerCase()))
    },
    filterByConditions (ids) {
      this.measuresConditionFilter = ids
    },
    updateValuesFromQuery (savedQuery) {
      const data = Object.assign({}, savedQuery)
      this.scorableFilter = data.query.hasOwnProperty('scorableFilter') ? data.query.scorableFilter === '1' : true // eslint-disable-line no-prototype-builtins
      this.currentActiveQueryScorableFilter = this.scorableFilter
      this.updateScorableState()
      this.updateResultToIncludeFromQuery(data.query)
      const hasMeasures = data.query.hasOwnProperty('measure') // eslint-disable-line no-prototype-builtins
      this.computedFilters[0].model = hasMeasures ? data.query.measure : []
      this.executeFilterAction(true)
    },
    setMeasureFilterItems () {
      if (this.scorableFilter) {
        this.setAnalyticsFilterItems(this.scorableMeasuresFilter)
      } else {
        this.setAnalyticsFilterItems(this.noScorableMeasuresFilter)
      }
      const ids = this.measuresConditions.map(mc => mc.id)
      this.filterByConditions(ids)
    },
    setResultsToIncludeFilterItems () {
      this.selectedResultsToInclude = 'all_results'
    },
    setScorableFilterItems () {
      this.executeFilterAction(false)
      this.setAnalyticsFilterItems(this.scorableFilter)
    },
    cancelChanges () {
      this.setSearchFilter('')
      this.resetMeasuresFilterHeaderFilters()
      this.scorableFilter = this.analyticsCurrentQuery.hasOwnProperty('scorableFilter') ? this.analyticsCurrentQuery.scorableFilter : true // eslint-disable-line no-prototype-builtins
      this.currentActiveQueryScorableFilter = this.scorableFilter
      this.cancelModelChange(this.scorableFilter ? this.scorableMeasuresFilter : this.noScorableMeasuresFilter, this.analyticsCurrentQuery.measure)
      this.setMeasureFilterItems()
      this.selectedResultsToInclude = this.getResultsToIncludeItemValue(this.analyticsCurrentQuery)
      const ids = this.measuresConditions.map(mc => mc.id)
      this.filterByConditions(ids)
    },
    updateScorableState () {
      this.executeFilterAction(false)
      this.updateCurrentFilter({ field: 'scorableFilter', value: this.scorableFilter })
      this.updateCurrentFilter({ field: 'results_to_include', value: this.selectedResultsToInclude })
      this.currentActiveQueryScorableFilter = this.scorableFilter
    },
    updateResultToIncludeFromQuery (savedQueryData) {
      const value = this.getResultsToIncludeItemValue(savedQueryData)
      this.selectedResultsToInclude = value
      this.updateCurrentFilter({ field: 'results_to_include', value: value })
    },
    getResultsToIncludeItemValue (source) {
      const resultsToInclude = source.hasOwnProperty('results_to_include') && source.results_to_include !== null ? source.results_to_include : 'all_results' // eslint-disable-line no-prototype-builtins
      if (!resultsToInclude) {
        return this.selectedResultsToInclude
      }
      const item = this.resultsToIncludeFilter.find(i => typeof resultsToInclude === 'string' ? i.value === resultsToInclude : i.value.column === resultsToInclude.column)
      return item.value
    },
    setSearchFilter (searchInputStringValue) {
      this.searchFilter = searchInputStringValue
    },
    resetMeasuresFilterHeaderFilters () {
      this.$refs.analyticsMeasuresFilterHeaderSearch.resetFilters()
    }
  }
}
</script>

<template>
  <div
    :class="{presented: question.presented_to_user, skipped: question.skipped}"
    v-html="questionTextValue"
  />
</template>

<script>

export default {
  name: 'MeasureQuestionResponse',
  props: {
    question: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    questionTextValue () {
      if (!this.question.presented_to_user) {
        return this.$t('notPresented')
      }
      if (this.question.skipped) {
        return this.$t('skipped')
      }
      return this.question.answers.reduce((carry, current) => {
        const text = `<div>${current.text || current.value}</div>`
        return carry ? `${carry}<br> ${text}` : text
      }, '')
    }
  }
}
</script>

export const HandleClickOutside = {
  created () {
    document.addEventListener('click', this.handleClickOutside)
  },
  destroyed () {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    handleClickOutside (event) {
      const refElement = this.$refs[this.refName]
      const target = event.target

      if (refElement && refElement !== target && !refElement.contains(target)) {
        if (this.closeFunction && typeof this.closeFunction === 'function') {
          return this.closeFunction()
        }
        this.opened = false
      }
    }
  },
  computed: {
    refName () {
      return `clickOutsideHandler${this._uid}`
    }
  }
}

import { mapGetters, mapMutations } from 'vuex'
import { ANALYTICS_UPDATE_CURRENT_QUERY, ANALYTICS_DELETE_PROPERTY_FROM_QUERY } from '@/store/modules/analytics/outcomes-explorer/constants.js'

const MIN = 'min'
const MAX = 'max'
const CUSTOM_EMPTY = 'custom_empty'

export const AnalyticsFilterHelpers = {
  data () {
    return {
      showError: false,
      errorType: MIN
    }
  },
  computed: {
    ...mapGetters({
      analyticsCurrentQuery: 'getAnalyticsCurrentQuery'
    })
  },
  methods: {
    ...mapMutations({
      updateCurrentFilter: ANALYTICS_UPDATE_CURRENT_QUERY,
      deleteFromQuery: ANALYTICS_DELETE_PROPERTY_FROM_QUERY
    }),
    resetToDefaultSelection () {
      this.computedFilters.forEach(filter => {
        this.setAnalyticsFilterItems(filter)
        this[`${filter.type}SetInitValues`](filter)
      })
    },
    setSelectedItemValue (selectedKey, filterKey, scopedItems) {
      let model

      // allow the model to be created from ONLY scopedItems if desired
      if (scopedItems) {
        const filteredModels = scopedItems.map(item => item.value)
        model = this[filterKey].model.filter(value => filteredModels.includes(value))
      } else {
        model = this[filterKey].model
      }

      this[selectedKey] = Array.isArray(model) ? model.map(v => v) : model
    },
    executeFilterAction (noEmit, scopedItems) {
      this.computedFilters.forEach(filter => {
        this.setSelectedItemValue(filter.selectedKey, filter.filterKey, scopedItems)
        this.updateCurrentFilter({ field: filter.filterName, value: this[filter.selectedKey] })
      })
      if (!noEmit) {
        this.$emit('filterChanged')
      }
    },
    setAnalyticsFilterItems (filter) {
      if (this.hasOwnProperty(filter.filterKey)) { // eslint-disable-line no-prototype-builtins
        if (!filter.hardcodedItems) {
          this[filter.filterKey].items = this[filter.availableKey].map(c => ({ value: c.uuid || c.umf_uuid || c.id, label: c[filter.label], rawData: c }))
        }
        const value = this.analyticsCurrentQuery[this[filter.filterKey].filterName]
        if (value) {
          this[filter.filterKey].model = typeof value === 'object' ? [...value] : value
          this.setSelectedItemValue(filter.selectedKey, filter.filterKey)
        } else {
          this[`${filter.type}SetInitValues`](filter)
        }
      }
    },
    clearAnalyticsFilterItems (key) {
      this.deleteFromQuery(key)
    },
    setInitValues (val, filter) {
      this[filter.filterKey].model = val
      this.setSelectedItemValue(filter.selectedKey, filter.filterKey)
      this.updateCurrentFilter({ field: filter.filterName, value: this[filter.selectedKey] })
    },
    checkboxSetInitValues (filter) {
      const ids = this[filter.filterKey].items.filter(i => filter.initAllSelected || i.initSelected).map(i => i.value)
      if (ids) {
        this.setInitValues(ids, filter)
      } else {
        this.setInitValues([], filter)
      }
    },
    cancelModelChange (filter, stateModel, selectedFilter = null) {
      let newModel
      if (selectedFilter) {
        this.selectedFilter = selectedFilter
      }
      switch (typeof stateModel) {
        case 'object':
          if (stateModel.find(value => value.uuid)) {
            newModel = stateModel.map(value => value.uuid)
          } else {
            newModel = stateModel
          }
          this.setInitValues(newModel, filter)
          break
        case 'string':
          newModel = stateModel
          filter.model = newModel
          break
        default:
          newModel = stateModel.map(value => value)
          this.setInitValues(newModel, filter)
          break
      }
    },
    radioSetInitValues (filter) {
      const found = this[filter.filterKey].items.find(i => i.initSelected)
      if (found) {
        this.setInitValues(found.value, filter)
      }
    },
    beforeExecute () {
      let canContinue = false

      if (this.isAndValidation) {
        canContinue = this.computedFilters.every(this.singleFilterCanContinue)
      } else {
        canContinue = this.computedFilters.some(this.singleFilterCanContinue)
      }

      this.showError = !canContinue
      return canContinue
    },

    singleFilterCanContinue (f) {
      if (f.limit && f.limit < f.model.length) {
        this.errorType = MAX
        return false
      }

      /**
       * Error if user selects custom range but doesn't enter any values. In this case,
       * the model will have a "custom" item but nothing else, so length = 1.
       * If user enters a value but then removes it, an item with an empty value is sent.
       * We account for these scenarios here.
       */
      if (f.model.includes('custom') && (f.model.length < 2 || f.model.includes(''))) {
        this.errorType = CUSTOM_EMPTY
        return false
      }

      this.errorType = MIN
      return f.required && !!f.model.length
    }
  },
  watch: {
    computedFilters: {
      handler (n) {
        if (n) {
          let resetError = false
          if (this.isAndValidation) {
            resetError = n.every(this.singleFilterCanContinue)
          } else {
            resetError = n.some(this.singleFilterCanContinue)
          }

          this.showError = resetError ? false : this.showError
        }
      },
      deep: true
    }
  }
}

<template>
  <div
    :ref="refName"
    class="filters-analytics"
  >
    <div class="dropdown">
      <button
        :disabled="analyticsRecordsLoading"
        class="btn btn-outline-secondary"
        type="button"
        @click="toggleOpen()"
      >
        {{ buttonText }}
        <i
          :class="{'fa-caret-down': !opened, 'fa-caret-up': opened}"
          class="fas filter-toggle"
        />
      </button>
      <div
        :class="{show: opened}"
        class="dropdown-menu"
      >
        <div class="filters-container">
          <div
            v-for="(filter, index) in filters"
            v-show="!filter.hidden"
            :key="index"
            class="filter"
          >
            <slot
              :filter="filter"
              :selectNone="selectNone"
              :selectAll="selectAll"
              :items="filter.items"
              name="title"
            >
              <div class="filter-title">
                <h5>{{ filter.title }}</h5>
                <div v-if="filter.type === 'checkbox'">
                  <a
                    href="javascript:void(0)"
                    @click="selectAll(filter)"
                  >{{ $t('all') }}</a> <span>|</span> <a
                    href="javascript:void(0)"
                    @click="selectNone(filter)"
                  >{{ $t('none') }}</a>
                </div>
              </div>
            </slot>
            <div class="filter-content">
              <slot
                :items="filter.items"
                :filter="filter"
                name="filter-content"
              >
                <div
                  v-for="(item, i) in filter.items"
                  :key="i"
                  :class="filter.filterName"
                  class="filter-content-item"
                >
                  <component
                    :is="item.customInputComponent"
                    v-if="item.customInputComponent"
                    :mapper="filter.customMapper"
                    :filter="filter"
                    :item="item"
                    @modelChange="modelChange"
                  />
                  <template v-else>
                    <CustomCheckbox
                      v-if="filter.type === 'checkbox'"
                      :id="`id_${filter.filterName+i}`"
                      v-model="filter.model"
                      :name="`label_${filter.filterName}`"
                      :hide-checkbox="filter.hideCheckbox"
                      :label="item.label"
                      :val="item.value"
                    />
                    <CustomRadio
                      v-if="filter.type === 'radio'"
                      :id="`id_${filter.filterName+i}`"
                      v-model="filter.model"
                      :name="`label_${filter.filterName}`"
                      :label="item.label"
                      :val="item.value"
                    />
                  </template>
                </div>
              </slot>
            </div>
          </div>
        </div>
        <span
          v-show="error && showError"
          class="error"
        >{{ error }}</span>
        <div class="dropdown-footer button-container">
          <button
            :disabled="false"
            type="button"
            class="btn btn-secondary btn-block"
            @click="applyFilter"
          >
            {{ $t('done') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HandleClickOutside } from '@/mixins/HandleClickOutside'
import CustomCheckbox from '@/components/common/CustomCheckbox'
import CustomRangeAge from '@/components/analytics/filters/CustomRangeAge'
import CustomRadio from '@/components/common/CustomRadio'
import { ANALYTICS_UPDATE_CURRENT_QUERY } from '@/store/modules/analytics/outcomes-explorer/constants.js'
import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'AnalyticsFilter',
  components: { CustomCheckbox, CustomRadio, CustomRangeAge },
  mixins: [HandleClickOutside],
  props: {
    customButtonText: {
      type: [String, Function],
      default: ''
    },
    filters: {
      type: Array,
      default: () => []
    },
    beforeExecute: {
      type: Function,
      default: () => true
    },
    error: {
      type: String,
      default: ''
    },
    showError: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      opened: false,
      selectedContact: []
    }
  },
  computed: {
    ...mapGetters({
      analyticsRecordsLoading: 'getAnalyticsRecordsLoading'
    }),
    buttonText () {
      return this.customButtonText ? this.getCustomButtonTextValue() : `${this.$t('show')}...`
    }
  },
  methods: {
    ...mapMutations({
      updateCurrentFilter: ANALYTICS_UPDATE_CURRENT_QUERY
    }),
    modelChange (params) {
      this.$emit('modelChange', params)
    },
    applyFilter () {
      const canContinue = this.beforeExecute()
      if (canContinue) {
        this.$emit('executeFilter', this.filters)
        this.opened = false
      }
    },
    selectAll (filter, items) {
      if (items) {
        // if passing a subset of items, add them to the existing model
        filter.model = [...new Set([...filter.model, ...items.map(i => i.value)])]
      } else {
        filter.model = filter.items.map(i => i.value)
      }
    },
    selectNone (filter) {
      filter.model = []
    },
    getCustomButtonTextValue () {
      return typeof this.customButtonText === 'function' ? this.customButtonText() : this.customButtonText
    },
    toggleOpen () {
      this.opened = !this.opened
      if (this.opened) {
        this.$emit('resetModel')
      }
    }
  }
}
</script>
<style lang="css" scoped>
  .error {
    padding-left: 20px;
  }
</style>

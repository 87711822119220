<template>
  <chart
    ref="highchartChart"
    class="analytics-chart bar-chart"
    :options="chartOptions"
    :callback="chartCallback"
  />
</template>

<script>
import { Chart } from 'highcharts-vue'
import { ChartsHelper } from '@/mixins/ChartsHelper'

export default {
  name: 'BarChart',
  components: {
    Chart
  },
  mixins: [ChartsHelper],
  props: {
    chart: {
      type: Object,
      default: () => {}
    },
    exporting: {
      type: Object,
      default: () => {}
    }
  },
  data: function () {
    this.chart.plotBackgroundColor = 'white'
    return {
      chartOptions: this.getDefaultChartConfig('column', this.chart, this.exporting)
    }
  },
  methods: {
    chartCallback () {
      this.$emit('adjustMenuIcon', this.$refs.highchartChart.$el)
    }
  }
}
</script>
<style lang="scss">
</style>

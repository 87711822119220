<template>
  <label>
    <input
      v-show="false"
      v-model="checkboxValue"
      type="checkbox"
      :value="val"
      :name="name"
    >
    <i
      v-if="!hideCheckbox"
      class="fal"
      :class="[checkboxValue.includes(val) ? 'fa-check-square' : 'fa-square']"
    />
    <slot name="customLabel"> {{ label }} </slot>
  </label>
</template>

<script>

export default {
  name: 'CustomCheckbox',
  props: ['name', 'val', 'value', 'label', 'customValue', 'hideCheckbox'],
  computed: {
    checkboxValue: {
      get: function () {
        return this.value
      },
      set: function () {
        this.emitChange()
      }
    }
  },
  watch: {
    customValue (newValue, oldValue) {
      if (newValue !== oldValue) {
        const index = this.value.findIndex(v => v === oldValue)
        if (index > -1) {
          this.value.splice(index, 1)
        }
        if (this.value.includes('custom')) {
          this.value.push(newValue)
        }
        this.$emit('input', this.value)
      }
    }
  },
  methods: {
    emitChange () {
      if (this.value) {
        if (this.value.includes(this.val)) {
          this.handleCustomRemove()
          this.handleNormalRemove()
        } else {
          this.handleCustomAdd()
          this.handleNormalAdd()
        }
        this.$emit('input', this.value)
      } else {
        this.$emit('input', [])
      }
    },
    handleNormalRemove () {
      const index = this.value.findIndex(v => v === this.val)
      this.value.splice(index, 1)
    },
    handleCustomRemove () {
      const customValueIndex = this.value.findIndex(v => v === this.customValue)
      if (customValueIndex > -1) {
        this.value.splice(customValueIndex, 1)
      }
    },
    handleNormalAdd () {
      this.value.push(this.val)
    },
    handleCustomAdd () {
      if (this.customValue) {
        this.value.push(this.customValue)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
label {
  span:hover,
  i:hover {
    cursor: pointer;
  }
}
</style>
